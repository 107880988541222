import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux"
import './ProductsPage.css'
import httpClient from '../components/helpers/httpClient'
import { useNavigate, Link, Navigate } from "react-router-dom"
import MenuBar from "../components/MenuBar"
import Header from "../components/Header"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import { updateFilters, updateProductsList, resetStateProductsPage } from "../store/productsPage"
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import DropDownFilter from '../components/DropDownFilter'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Dropdown from '../components/Dropdown'
import CheckBox from '../components/CheckBox'
import Slider from '../components/Slider'
import { DateField } from '@mui/x-date-pickers'
import { toast } from "react-toastify"
import Pagination from '../components/Pagination'
import FileDropper from '../components/FileDropper'
import Label from '../components/Label'

function ProductsPage() {
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [limit, setLimit] = useState(20)
  const [totalPages, setTotalPages] = useState(1)
  const [tableBig, setTableBig] = useState(true)
  const [imageURL, setImageURL] = useState("")
  const [fileThumbURL, setFileThumbURL] = useState("")
  const [fileURL, setFileURL] = useState("")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const filters = useSelector(state => state.productsPage.filters)
  const products = useSelector(state => state.productsPage.productsList)
  const noImage = require("../assets/images/no_image.png")

  const arrayOfBrands = [...new Set(products.map(product => {
    if (product.brand !== null && product.brand !== undefined) {
      return product.brand
    }
  }).filter(place => place !== undefined))]

  const arrayOfProducts = [...new Set(products.map(product => {
    if (product.product !== null && product.product !== undefined) {
      return product.product
    }
  }).filter(place => place !== undefined))]

  const fetchProducts = async () => {
    setLoadingProducts(true)

    const requestData = {
      filters: {
        brand: filters.brandFilter,
        search_word: filters.searchWord,
        product: filters.productFilter,
        search_fields: ["brand", "product", "product_producer_id", "product_internal_sku", "product_description"],
        hide_inactive: filters.hideInactive !== false
      },
      sort: filters.sortFilter,
      sortAscending: filters.sortAscending,
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/products/get?page=" + filters.page + "&limit=" + limit, requestData)
    dispatch(updateProductsList(response.data[0]))
    setTotalPages(response.data[1])

    setLoadingProducts(false)
  }

  useEffect(() => {
    fetchProducts()

    const inputFields = document.querySelectorAll(".input_field")
  }, [])

  useEffect(() => {

    fetchProducts()
      .catch(err => {
        console.log(err)
      })

  }, [filters])

  const handleOnClickRow = (event) => {
    const productID = event.currentTarget.getAttribute("data-product-id")

    navigate(`/products/${productID}`)
  }

  const handleSetSortFilter = (event) => {

    const innerHTML = event.currentTarget.innerHTML
    const updatedFilters = { ...filters }

    switch (innerHTML) {
      case "id":
        if (filters.sortFilter == "id") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "id"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Brand":
        if (filters.sortFilter == "brand") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "brand"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Product":
        if (filters.sortFilter == "product") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "product"
          updatedFilters["sortAscending"] = true
        }
        break
      case "SKU producer":
        if (filters.sortFilter == "product_producer_id") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "product_producer_id"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Internal SKU":
        if (filters.sortFilter == "product_internal_sku") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "product_internal_sku"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Supplier SKU":
        // Client-side sorting not implemented for this field as it's a complex object
        break
      case "Description":
        if (filters.sortFilter == "product_description") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "product_description"
          updatedFilters["sortAscending"] = true
        }
        break
    }
    updatedFilters["page"] = 1
    dispatch(updateFilters(updatedFilters))
  }


  const handleOnClickDropdownItem = (event) => {

    const filter = event.currentTarget.textContent
    event.target.parentElement.previousSibling.firstChild.textContent = filter

    const updatedFilters = { ...filters }

    switch (event.target.parentElement.id) {
      case "products__dropdownList--brand":
        updatedFilters["brandFilter"] = filter
        document.getElementById("products__dropdownList--brand").classList.remove("active")
        break

      case "products__dropdownList--product":
        updatedFilters["productFilter"] = filter
        document.getElementById("products__dropdownList--product").classList.remove("active")
        break
    }

    updatedFilters["page"] = 1
    dispatch(updateFilters(updatedFilters))
  }

  const handleSearchWord = (event) => {
    const updatedFilters = { ...filters }
    updatedFilters["searchWord"] = event.target.value
    updatedFilters["page"] = 1
    
    dispatch(updateFilters(updatedFilters))
  }

  const handleClearSearchBar = () => {
    if (filters.searchWord == "") {
      return
    } else {
      const updatedFilters = { ...filters }
      updatedFilters["searchWord"] = ""
      updatedFilters["page"] = 1

      dispatch(updateFilters(updatedFilters))
    }
  }

  const handlePagination = (event) => {
    const element = event.currentTarget

    if (element.classList.contains("grayed-out")) {
      return
    }

    const type = element.dataset.type

    const updatedFilters = { ...filters }

    switch (type) {

      case "previous":
        updatedFilters["page"] = filters.page - 1

        dispatch(updateFilters(updatedFilters))
        break
      case "next":
        updatedFilters["page"] = filters.page + 1

        dispatch(updateFilters(updatedFilters))
        break
    }
  }

  const handleCreateNewProduct = () => {
    const newProductForm = document.getElementById("new-product-form")
    const allProductsTable = document.getElementById("all-products-table")
    const saveButton = document.getElementById("products__btn-save")
    const filters = document.getElementById("products__table-btns")

    newProductForm.classList.toggle("hide")
    allProductsTable.classList.toggle("big")
    saveButton.classList.toggle("hide")
    filters.classList.toggle("hide")

    setTableBig(!tableBig)
  }

  const handleSaveNewProduct = async () => {
    document.getElementById("products__btn-save").disabled = true

    let okToSave = true
    const formData = {}

    const emailValidation = /\S+@\S+\.\S+/

    const inputElements = document.querySelectorAll('.input_field__input_box--text')

    inputElements.forEach(element => {
      const fieldName = element.name
      formData[fieldName] = element.value
    })

    // Get the is_active hidden input value and convert it to a boolean
    const isActiveInput = document.getElementById('product__is_active')
    if (isActiveInput) {
      formData['is_active'] = isActiveInput.value === 'true'
    }

    console.log(fileThumbURL)

    formData['picture_link'] = imageURL
    formData['picture_thumb_link'] = fileThumbURL
    formData['technical_fiche_link'] = fileURL

    console.log(formData)

    try {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + "/api/product/create", formData),
        {
          pending: "Creating a new product...",
          success: "New product created 👍",
          error: {
            render({data}) {
              // When the promise reject, data will contain the error
              const errorMessage = data.response?.data?.error || "Something went wrong 😢"
              return errorMessage
            }
          }
        }
      )
      console.log(response.data)

      // cleaning up form
      inputElements.forEach(element => {
        element.value = ""
      })

      setFileURL("")
      setImageURL("")

      fetchProducts()

      navigate(`/products/${response.data.id}`)
    } catch (error) {
      console.error("Error creating product:", error)
    } finally {
      document.getElementById("products__btn-save").disabled = false
    }
  }

  return (
    <div className='template'>

      <Header>
        <div className="header__filters" id="products__table-btns">
          <div className="search-bar" id="products__search-bar--table" onClick={handleClearSearchBar}>
            <input placeholder="Search product..." value={filters.searchWord} onChange={handleSearchWord} />
            {filters.searchWord == "" ? <SearchIcon /> : <ClearIcon />}
          </div>
          <DropDownFilter
            idList="products__dropdownList--brand"
            placeholderText="Filter on brand"
            listArray={arrayOfBrands.sort((a, b) => a.localeCompare(b))}
            onClickItem={handleOnClickDropdownItem}
            filters={filters}
            value={filters.brandFilter}
            filter={"brandFilter"}
            setValue={updateFilters}
          />
          <DropDownFilter
            idList="products__dropdownList--product"
            placeholderText="Filter on product"
            listArray={arrayOfProducts.sort((a, b) => a.localeCompare(b))}
            onClickItem={handleOnClickDropdownItem}
            filters={filters}
            value={filters.productFilter}
            filter={"productFilter"}
            setValue={updateFilters}
          />
          <div className="hide-inactive-filter">
            <Slider 
              text="Hide Inactive" 
              defaultState={filters.hideInactive !== false}
              state={filters.hideInactive !== false}
              size={1.2}
              fontSize="0.8rem"
              onClickFunction={(id, newState) => {
                if (!loadingProducts) {
                  const updatedFilters = { ...filters, hideInactive: newState, page: 1 };
                  dispatch(updateFilters(updatedFilters));
                }
              }}
            />
          </div>
        </div>

        <button className="primary-button-header--right hide" id="products__btn-save" onClick={handleSaveNewProduct}>SAVE</button>

      </Header>

      <MenuBar />

      <div className="template__body" id='body-products'>
        <button className="floating__button--bottom-right" id="add--btn" onClick={handleCreateNewProduct} />

        <div className="body__section hide" id="new-product-form">
          <div className="form__section">

            <div className="input_field">
              <label className="input_field__label" htmlFor="email">Brand</label>
              <input className="input_field__input_box--text" id="products__brand" type="text" name="brand" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Product</label>
              <input className="input_field__input_box--text" id="products__product" type="text" name="product" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">SKU producer</label>
              <input className="input_field__input_box--text" id="products__SKU_producer" type="text" name="product_producer_id" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Description</label>
              <textarea className="input_field__input_box--text" id="product__product_description" type="text" name="product_description" />
            </div>

          </div>

        </div>

        <div className='body__section big' id='all-products-table'>
          <div className='table'>
            <div className='table__header-5'>
              <div className='table__header--field' onClick={handleSetSortFilter}></div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Brand</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Product</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Description</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Internal SKU</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Supplier SKU</div>
            </div>
            <div className='table__rows_container'>

              {
                loadingProducts ?
                  <LoadingSpinner /> :

                  products?.map((product, index) => {
                    return (
                      <div className='table__row-5' key={index} onClick={handleOnClickRow} data-product-id={product.id}>
                        <div className='table__row--field'>
                          {
                            product.picture_link == null | product.picture_link == "" ?
                              <img src={noImage} />
                              :
                              <img src={`${process.env.PUBLIC_URL}${product.picture_link}`}/>

                          }
                        </div>
                        <div className='table__row--field'>{product.brand}</div>
                        <div className='table__row--field'>{product.product}</div>
                        <div className='table__row--field'>{product.product_description}</div>
                        <div className='table__row--field'>{product.product_internal_sku}</div>
                        <div className='table__row--field'>
                          {product.supplier_skus && product.supplier_skus.length > 0 ? (
                            <div className='labels-container'>
                              {product.supplier_skus.map((skuInfo, i) => (
                                <span key={i} className="label-item sku-label">
                                  {`${skuInfo.supplier_name}: ${skuInfo.sku}`}
                                </span>
                              ))}
                            </div>
                          ) : null}
                          {product.is_active === false && (
                            <span className="label-item inactive-label">Inactive</span>
                          )}
                        </div>
                      </div>
                    )
                  })
              }
            </div>
            <div className="pagination">
              <ArrowBackIosIcon className={`pagination__btn ${filters.page == 1 ? "grayed-out" : ""}`} onClick={handlePagination} data-type="previous" id="prevPage" />
              <ArrowForwardIosIcon className={`pagination__btn ${filters.page == totalPages ? "grayed-out" : ""}`} onClick={handlePagination} data-type="next" id="nextPage" />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ProductsPage
// TemplateModal.jsx
import React, { useState, useEffect, useRef } from 'react';
import { MdClose } from "react-icons/md";
import CheckBox from '../../CheckBox';
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
import './TemplateModal.css'

const TemplateModal = ({ 
  isOpen, 
  onClose, 
  onSave, 
  initialName = '', 
  initialDescription = '', 
  initialIsPublic = true,
  templates = [] // Added templates list prop
}) => {
  const [name, setName] = useState(initialName);
  const [description, setDescription] = useState(initialDescription);
  const [isPublic, setIsPublic] = useState(initialIsPublic);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Filter templates based on input
    if (name.trim()) {
      const filtered = templates.filter(template => 
        template.name.toLowerCase().includes(name.toLowerCase())
      );
      setFilteredTemplates(filtered);
    } else {
      setFilteredTemplates([]);
    }
  }, [name, templates]);

  useEffect(() => {
    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          inputRef.current && !inputRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isOpen) return null;

  const handleSave = () => {
    if (!name.trim()) {
      toast.error("Template name is required");
      return;
    }

    // Check if template with this name already exists
    const existingTemplate = templates.find(
      template => template.name.toLowerCase() === name.toLowerCase()
    );

    if (existingTemplate) {
      setShowConfirmation(true);
    } else {
      handleSaveTemplate();
    }
  };

  const handleSaveTemplate = async () => {
    setIsSaving(true);
    try {
      await onSave({ name, description, isPublic });
      // If we get here, the save was successful
    } catch (error) {
      console.error('Error saving template:', error);
      // The error toast should already be shown by the parent component
    } finally {
      setIsSaving(false);
    }
  };

  const handleConfirmOverwrite = () => {
    setShowConfirmation(false);
    handleSaveTemplate();
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setShowDropdown(true);
  };

  const handleSelectTemplate = (templateName) => {
    setName(templateName);
    setShowDropdown(false);
  };

  return (
    <div className="popup-dropdown__overlay">
      <div className="popup-dropdown__popup template-modal">
        <div className="template-modal-header">
          <h3 className="title">Save Purchase Order Template</h3>
          <button 
            className="popup-dropdown__clear-button close-button" 
            onClick={onClose}
            disabled={isSaving}
          >
            <MdClose />
          </button>
        </div>
        
        <div className="template-modal-body">
          <div className="form-group">
            <label htmlFor="templateName">Template Name *</label>
            <div className="template-name-container">
              <input 
                id="templateName"
                type="text" 
                value={name} 
                onChange={handleNameChange}
                onFocus={() => setShowDropdown(true)}
                placeholder="Enter a name for this template"
                className="popup-dropdown__search"
                ref={inputRef}
                disabled={isSaving}
              />
              {showDropdown && filteredTemplates.length > 0 && (
                <div className="template-name-dropdown" ref={dropdownRef}>
                  {filteredTemplates.map((template, index) => (
                    <div 
                      key={index}
                      className="template-name-option"
                      onClick={() => handleSelectTemplate(template.name)}
                    >
                      {template.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          
          <div className="form-group">
            <label htmlFor="templateDescription">Description</label>
            <textarea 
              id="templateDescription"
              value={description} 
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter an optional description"
              rows={3}
              className="popup-dropdown__search"
              style={{ resize: 'vertical', minHeight: '80px' }}
              disabled={isSaving}
            />
          </div>
          
          <div className="form-group checkbox-group">
            <CheckBox
              state={isPublic}
              setState={setIsPublic}
              margin="0"
              darkMode={true}
              text="Make this template available to all users"
              textColor="var(--secondairy-light-medium-gray)"
              textSize="14px"
              disabled={isSaving}
            />
          </div>
        </div>
        
        <div className="template-modal-footer">
          <button 
            className="cancel-button" 
            onClick={onClose}
            disabled={isSaving}
          >
            Cancel
          </button>
          <button 
            className="save-button" 
            onClick={handleSave}
            disabled={isSaving}
          >
            {isSaving ? (
              <div className="save-button-loading">
                <CircularProgress size={20} color="inherit" />
                <span>Saving...</span>
              </div>
            ) : (
              "Save Template"
            )}
          </button>
        </div>

        {/* Confirmation Dialog */}
        {showConfirmation && (
          <div className="confirmation-overlay">
            <div className="confirmation-dialog">
              <h4>Template already exists</h4>
              <p>A template with the name "{name}" already exists. Do you want to overwrite it?</p>
              <div className="confirmation-buttons">
                <button 
                  className="cancel-button" 
                  onClick={() => setShowConfirmation(false)}
                  disabled={isSaving}
                >
                  Cancel
                </button>
                <button 
                  className="save-button" 
                  onClick={handleConfirmOverwrite}
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <div className="save-button-loading">
                      <CircularProgress size={16} color="inherit" />
                      <span>Saving...</span>
                    </div>
                  ) : (
                    "Overwrite"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateModal;
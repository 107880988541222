import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { toast } from "react-toastify"
import { DateField } from '@mui/x-date-pickers'
import { noteTypes } from '../../../assets/data/data'
import PopupDropdown from '../../PopupDropdown'
import { PopupWindow } from '../../PopupWindow'
import Note from '../../Note'
import httpClient from '../../helpers/httpClient'
import { formatDate } from '../../helpers/formatDate'
import { isValidDateDayJS } from '../../helpers/globalFns'

const GeneralSection = forwardRef(({ unit, setUnit, actionType, contactsList, loading, creatingNote, setCreatingNote }, ref) => {
  const [notes, setNotes] = useState([])
  const [noteDetailPopupIsActive, setNoteDetailPopupIsActive] = useState(false)
  const [selectedNote, setSelectedNote] = useState(null)
  const [newNoteObj, setNewNoteObj] = useState({})

  const UNIT_TYPES = ["Apartment", "House", "Parking", "Office", "Commercial", "Warehouse", "Other"]

  const fetchNotes = async () => {
    if (!unit) return

    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/notes/get/${unit.id}?type=unit`)
    if (response.status == 200) {
      setNotes(response.data)
    }
  }

  useEffect(() => {
    if (unit) {
      fetchNotes()
    }
  }, [unit])

  // Expose methods via ref
  useImperativeHandle(ref, () => ({
    handleCreateNote: async () => {
      if (!isValidDateDayJS(newNoteObj?.noteDate)) {
        toast.error("Date not valid or is empty")
        return false
      }

      if (!newNoteObj.noteType || !newNoteObj.contact || !newNoteObj.subject) {
        toast.error("Type, contact and subject are required")
        return false
      }

      const data = { ...newNoteObj }
      data["unitID"] = unit.id

      try {
        await toast.promise(
          httpClient.post(process.env.REACT_APP_API_URL + `/api/notes/add`, data),
          {
            success: `Note has been created!`,
            error: "Something went wrong",
            pending: `Creating note...`
          }
        )

        // cleanup state
        const prevStateNewNote = { ...newNoteObj }
        Object.keys(prevStateNewNote).forEach(key => {
          prevStateNewNote[key] = ''
        })
        setNewNoteObj(prevStateNewNote)
        setCreatingNote(false)
        await fetchNotes()
        return true
      } catch (error) {
        return false
      }
    }
  }))

  const handleOnChangeInput = (e) => {
    const key = e.currentTarget.getAttribute("name")
    const value = e.currentTarget.value
    setUnit(prev => ({ ...prev, [key]: value }))
  }

  const handleOnChangeStatus = (value) => {
    setUnit(prev => ({ ...prev, status: value }))
  }

  const handleCreateNote = () => {
    setCreatingNote(true)
  }

  const setStateNewNote = (key, value) => {
    setNewNoteObj(prev => ({ ...prev, [key]: value }))
  }

  const handleSetStateNewNoteType = (value) => {
    setNewNoteObj(prev => ({ ...prev, noteType: value }))
  }

  const handleSetStateNewNoteContact = (value) => {
    setNewNoteObj(prev => ({ ...prev, contact: value }))
  }

  const handleOnChangeType = (value) => {
    setUnit(prev => ({ ...prev, type: value }))
  }

  return (
    <div className={`popout_body__content_container ${actionType == 'add' ? "small" : ""}`} id="unit-general-section">
      <div className='panel'>
        <h2>Details</h2>
        <div className='form__section no-border no-padding'>
          <div className='form__section'>
            <div className='form__section--content'>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="unit_name">Status</label>
                <PopupDropdown
                  options={["available", "sold", "rented", "inactive"]}
                  value={unit?.status}
                  onChange={handleOnChangeStatus}
                  title={"Select status"}
                  labelView={true}
                  labelColors={{
                    available: "green",
                    sold: "red",
                    rented: "blue",
                    inactive: "gray"
                  }}
                />
              </div>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="unit_name">Unit name</label>
                <input
                  className="input_field__input_box--text"
                  id="unit_name"
                  type="text"
                  name="unit_name"
                  value={unit?.unit_name}
                  onChange={handleOnChangeInput}
                />
              </div>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="unit_name">Type</label>
                <PopupDropdown
                  searchBar={true}
                  options={UNIT_TYPES}
                  value={unit?.type}
                  onChange={handleOnChangeType}
                  title={"Select unit type"}
                />
              </div>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="unit_address">Address</label>
                <input
                  className="input_field__input_box--text"
                  id="unit_address"
                  type="text"
                  name="address"
                  value={unit?.address}
                  onChange={handleOnChangeInput}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {actionType != 'add' && (
        <div className='panel'>
          <h2>Notes</h2>
          <div className='form__section'>
            <div className='form__section--content'>
              <PopupWindow
                popupID={"popup-unit-notes-create"}
                isActive={creatingNote}
                setIsActive={setCreatingNote}
              >
                <div className='note__container--popup'>
                  <div className='form__section no-border no-padding'>
                    <div className="input_field--combo-grid">
                      <label className="input_field__label" htmlFor="note_date">Note date</label>
                      <DateField
                        className="date-picker"
                        format="DD-MM-YYYY"
                        value={newNoteObj.noteDate}
                        onChange={(value) => {
                          // Set the time to noon to avoid timezone issues
                          if (value) {
                            value = value.hour(12).minute(0).second(0)
                          }
                          setStateNewNote("noteDate", value)
                        }}
                        slotProps={{
                          textField: {
                            inputProps: {
                              placeholder: "DD-MM-YYYY"
                            }
                          }
                        }}
                      />
                    </div>
                    <div className="input_field--combo-grid">
                      <label className="input_field__label" htmlFor="note_type">Note type</label>
                      <PopupDropdown
                        options={Object.keys(noteTypes)}
                        value={newNoteObj.noteType}
                        onChange={handleSetStateNewNoteType}
                        title={"Select note type"}
                      />
                    </div>
                    <div className="input_field--combo-grid">
                      <label className="input_field__label" htmlFor="note_type">Contact</label>
                      <PopupDropdown
                        options={contactsList}
                        value={newNoteObj.contact}
                        onChange={handleSetStateNewNoteContact}
                        title={"Select contact"}
                        searchBar={true}
                      />
                    </div>
                    <div className="input_field--combo-grid">
                      <label className="input_field__label" htmlFor="note_subject">Subject</label>
                      <input
                        className="input_field__input_box--text"
                        id="note_subject"
                        type="text"
                        name="note_subject"
                        value={newNoteObj.note_subject}
                        onChange={(e) => setStateNewNote("subject", e.currentTarget.value)}
                      />
                    </div>
                    <div className="input_field">
                      <label className="input_field__label" htmlFor="note_content">Content</label>
                      <textarea
                        className="input_field__input_box--text-area"
                        id="note_content"
                        type="text"
                        name="note_content"
                        value={newNoteObj.note_content}
                        onChange={(e) => setStateNewNote("content", e.currentTarget.value)}
                      />
                    </div>
                  </div>
                </div>
              </PopupWindow>

              <PopupWindow
                popupID={"popup-unit-notes-detail"}
                isActive={noteDetailPopupIsActive}
                setIsActive={setNoteDetailPopupIsActive}
              >
                {selectedNote && (
                  <div className='note__container--popup'>
                    <div className='date_note--popup'>
                      <div className='popup_window__subtitle'>Date:</div>
                      <p>{formatDate(selectedNote.date_note)}</p>
                    </div>
                    <div className='date_note--popup'>
                      <div className='popup_window__subtitle'>Created by:</div>
                      <p>{selectedNote.note_maker_user_id?.first_name}</p>
                    </div>
                    <div className='date_note--popup'>
                      <div className='popup_window__subtitle'>Contact:</div>
                      {selectedNote.contact_id ? (
                        <p>{selectedNote.contact_id.first_name} {selectedNote.contact_id.last_name}</p>
                      ) : selectedNote.company_id ? (
                        <p>{selectedNote.company_id.company_name} (company)</p>
                      ) : null}
                    </div>
                    <div className='date_note--popup'>
                      <div className='popup_window__subtitle'>Subject:</div>
                      <p>{selectedNote.subject}</p>
                    </div>
                    <div className='note_text--popup'>
                      <div className='popup_window__subtitle'>Note:</div>
                      <p>{selectedNote.note}</p>
                    </div>
                  </div>
                )}
              </PopupWindow>

              <button className='more--btn no-margin' onClick={handleCreateNote}>+</button>
              {notes.map((note, index) => (
                <Note
                  key={index}
                  popupWindowID="popup-contact-notes"
                  noteObj={note}
                  type={note.type}
                  note={note.note}
                  subject={note.subject}
                  date={note.date_note}
                  setSelectedNote={setSelectedNote}
                  setPopupActive={setNoteDetailPopupIsActive}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
})

GeneralSection.displayName = 'GeneralSection'
export default GeneralSection
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {},
    reducers: {
      addUserInfo: (state, action) => {
        return state = action.payload 
      }
    }
})

export const { addUserInfo } = userSlice.actions
export default userSlice.reducer
import React, { useState, useEffect } from "react"
import httpClient from '../components/helpers/httpClient'
import "./SignupPage.css"
import { useNavigate, Link } from "react-router-dom"
import { toast } from "react-toastify"
import CircularProgress from '@mui/material/CircularProgress'

function SignupPage() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const logo_black = require("../assets/logos/Logo-Vitruvi-transparant_Wit.png")
  const navigate = useNavigate()

  useEffect(() => {

  }, [])

  const signupUser = async () => {
    setLoading(true)

    const type = "TBA"

    try {
      const data = { email, password, type }
      const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/register", data)

      toast.success("You are succesfully signed up")
      navigate("/")

    } catch (error) {
      if (error.response.status === 409) {
        toast.error("User with this email address already exists")
      }
    }

    setLoading(false)
  }

  return (
    <div className="body__signup_page">
      <Link to='/'><img className="logo" src={logo_black} alt="stoqup logo black" /></Link>
      <form className='login-form'>
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder='EMAIL'
        />
        <input
          value={password}
          onChange={e => setPassword(e.target.value)}
          type='password'
          placeholder='PASSWORD'
        />

        <button type='button' className='button-login' onClick={signupUser}><div className={`${loading ? "hide" : ""}`}>SIGN UP</div>{loading && <CircularProgress color="inherit" size={"2.3rem"} />}</button>
      </form>
    </div>
  )
}

export default SignupPage

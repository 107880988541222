import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCompanyFunctions } from "./store/companiesPage"
import { updateTypesList } from "./store/projectsPage"
import httpClient from './components/helpers/httpClient'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from "react-toastify"
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import LoginPage from "./pages/LoginPage"
import SignupPage from "./pages/SignupPage"
import DashboardPage from "./pages/DashboardPage"
import ContactsPage from "./pages/ContactsPage"
import ContactDetailPage from "./pages/ContactDetailPage"
import CompaniesPage from "./pages/CompaniesPage"
import CompanyDetailPage from "./pages/CompanyDetailPage"
import ProductsPage from "./pages/ProductsPage"
import ProductDetailPage from "./pages/ProductDetailPage"
import ProjectsPage from "./pages/ProjectsPage"
import ProjectDetailPage from "./pages/ProjectDetailPage"
import TransactionsPage from "./pages/TransactionsPage"

function App() {
  const dispatch = useDispatch()
  const companyFunctions = useSelector(state => state.companiesPage.companyFunctions)
  const typesList = useSelector(state => state.projectsPage.typesList)

  useEffect(() => {
    console.log("App mounted")
    initializeAppState()
  }, [])

  const initializeAppState = async () => {
    await Promise.all([
      initializeCompanyFunctions(),
      initializeTypesList(),
    ])
  }

  const initializeCompanyFunctions = async () => {
    if (!companyFunctions || companyFunctions.length === 0) {
      try {
        const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/company/functions")
        if (response.status === 200) {
          dispatch(updateCompanyFunctions(response.data))
        } else {
          console.error("Error fetching company functions:", response.status)
        } 
      } catch (error) {
        console.error("Error fetching company functions:", error)
      }
    }
  }

  const initializeTypesList = async () => {
    if (!typesList) {
      try {
        const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/projects/types")
        if (response.status === 200) {
          dispatch(updateTypesList(response.data))
        } else {
          console.error("Error fetching types:", response.status)
        }
      } catch (error) {
        console.error("Error fetching types:", error)
      }
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/contacts/:contactID" element={<ContactDetailPage />} />
          <Route path="/companies" element={<CompaniesPage />} />
          <Route path="/companies/:companyID" element={<CompanyDetailPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/products/:productID" element={<ProductDetailPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/projects/:projectID" element={<ProjectDetailPage />} />
          <Route path="/transactions" element={<TransactionsPage />} />
        </Routes>

        <div className="App">
          <ToastContainer position="bottom-right" theme="colored" />
        </div>
      </Router>
    </LocalizationProvider >
  )
}

export default App

import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import './PurchaseSection.css'
import { useNavigate } from 'react-router-dom'
import { MdEmail, MdExpand, MdSave, MdClose, MdDelete } from "react-icons/md"
import { FaPhoneAlt } from "react-icons/fa"
import PopupDropdown from '../../PopupDropdown'
import httpClient from '../../helpers/httpClient'
import { toast } from "react-toastify"
import { GrTemplate } from "react-icons/gr"
import { MdOutlineCloudDownload } from "react-icons/md";
import { NumericFormat } from 'react-number-format'
import CheckBox from '../../CheckBox'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import PopupInput from '../../PopupInput'
import TemplateModal from './TemplateModal'
import CircularProgress from '@mui/material/CircularProgress'
import { IoAddCircleOutline } from 'react-icons/io5'

const PurchaseSection = forwardRef(({ unit }, ref) => {
  const [pocs, setPocs] = useState([])
  const [contacts, setContacts] = useState([])
  const [purchaseOrderLines, setPurchaseOrderLines] = useState([])
  const [productCategoryList, setProductCategoryList] = useState([])
  const [products, setProducts] = useState([])
  const [productGroups, setProductGroups] = useState([])
  const [templateMode, setTemplateMode] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [collapsedCategories, setCollapsedCategories] = useState({})
  const [templates, setTemplates] = useState([])
  const [templateName, setTemplateName] = useState('')
  const [templateDescription, setTemplateDescription] = useState('')
  const [isPublicTemplate, setIsPublicTemplate] = useState(true)
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false)
  const [isLoadingPurchaseOrders, setIsLoadingPurchaseOrders] = useState(true)
  const [showingAlternativeButtons, setShowingAlternativeButtons] = useState(null)

  // Define status options
  const statusOptions = [
    { id: 'to_start', name: 'To start' },
    { id: 'under_review', name: 'Under review' },
    { id: 'finalized', name: 'Finalized' },
  ]

  // Define label colors mapping for the PopupDropdown
  const statusLabelColors = {
    to_start: 'orange',
    under_review: 'blue',
    finalized: 'green'
  }

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all data in parallel
        await Promise.all([
          fetchPocs(),
          fetchContacts(),
          fetchProductCategories(),
          fetchProducts(),
          fetchProductGroups(),
          fetchPurchaseOrders(),
          fetchTemplates()
        ]);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchData();
  }, []);

  useImperativeHandle(ref, () => ({
    handleSavePurchaseOrderLines: async () => {
      console.log(purchaseOrderLines)
      try {
        const response = await httpClient.post(
          process.env.REACT_APP_API_URL + `/api/units/purchase-orders/save`,
          {
            unitID: unit.id,
            purchaseOrderLines
          }
        );

        if (response.status === 200 && response.data.purchaseOrderLines) {
          setPurchaseOrderLines(response.data.purchaseOrderLines);
        }

        return response.status === 200;
      } catch (error) {
        console.error('Error saving purchase order lines:', error)
        return false;
      }
    }
  }))

  const fetchPurchaseOrders = async () => {
    setIsLoadingPurchaseOrders(true);
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/units/purchase-orders/${unit.id}`);
      if (response.status === 200) {
        setPurchaseOrderLines(response.data.purchaseOrderLines);
      }
    } catch (error) {
      console.error('Error fetching purchase orders:', error);
      toast.error('Failed to load purchase orders');
    } finally {
      setIsLoadingPurchaseOrders(false);
    }
  }

  const fetchPocs = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/units/pocs/get?unitID=${unit.id}`)
    if (response.status == 200) {
      setPocs(response.data)
    }
  }

  const fetchContacts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/contacts/get`)
    if (response.status == 200) {
      setContacts(response.data)
    }
  }

  const fetchProductCategories = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/product-categories/get`)
    if (response.status == 200) {
      setProductCategoryList(response.data)
    }
  }

  const fetchProducts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/products/get`)
    if (response.status == 200) {
      setProducts(response.data.map(product => ({
        ...product,
        name: `${product.product_internal_sku} | ${product.brand} - ${product.product}`
      })))
    }
  }

  const fetchProductGroups = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/product/groups/get`)
    if (response.status == 200) {
      setProductGroups(response.data)
    }
  }

  const fetchTemplates = async () => {
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL + `/api/purchase-order-templates/get`
      );

      if (response.status === 200) {
        setTemplates(response.data);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
      throw error;
    }
  }

  const handleAddPoc = async (poc) => {
    const data = {
      unitID: unit.id,
      contactID: poc.id
    }
    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + `/api/units/pocs/create`, data),
      {
        success: `Person of contact has been added!`,
        error: "Something went wrong",
        pending: `Adding person of contact...`
      }
    )

    fetchPocs()
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
    toast.success("Copied to clipboard!")
  }

  const handleAddProductCategory = (category) => {
    setPurchaseOrderLines([...purchaseOrderLines, {
      category_name: category.name,
      category_id: category.id,
      order_lines: []
    }])
  }

  const handleAddProduct = (type, categoryIndex, valueObj) => {
    let orderLineCategory = purchaseOrderLines[categoryIndex]
    if (type === "product") {
      let newOrderLine = {
        product_id: valueObj.id,
        product_name: valueObj.product,
        product_brand: valueObj.brand,
        quantity: 0,
        isGroupItem: false
      }
      orderLineCategory.order_lines.push(newOrderLine)
    } else if (type === "group") {
      // Add group header
      let groupHeader = {
        isGroup: true,
        group_id: valueObj.id,
        group_name: valueObj.group_name,
        isExpanded: true, // Initial state
        products: valueObj.products.map(product => ({
          product_id: product.id,
          product_name: product.product,
          product_brand: product.brand,
          quantity: 0,
          isGroupItem: true,
          parent_group_id: valueObj.id
        }))
      }
      orderLineCategory.order_lines.push(groupHeader)
    }
    setPurchaseOrderLines([...purchaseOrderLines])
  }

  const handleIndividualProductChange = (categoryIndex, orderLineIndex, field, value, productIndex = null) => {
    const updatedLines = [...purchaseOrderLines];

    if (productIndex !== null) {
      // Handle product within a group
      const groupProduct = updatedLines[categoryIndex].order_lines[orderLineIndex].products[productIndex];
      groupProduct[field] = value;

      // Calculate quantity_total if quantity or quantity_margin changes
      if (field === 'quantity' || field === 'quantity_margin') {
        const quantity = parseFloat(groupProduct.quantity) || 0;
        const margin = parseFloat(groupProduct.quantity_margin) || 0;
        groupProduct.quantity_total = quantity * (1 + margin / 100);
      }
    } else {
      // Handle individual product
      const orderLine = updatedLines[categoryIndex].order_lines[orderLineIndex];
      orderLine[field] = value;

      // Calculate quantity_total if quantity or quantity_margin changes
      if (field === 'quantity' || field === 'quantity_margin') {
        const quantity = parseFloat(orderLine.quantity) || 0;
        const margin = parseFloat(orderLine.quantity_margin) || 0;
        orderLine.quantity_total = quantity * (1 + margin / 100);
      }
    }

    setPurchaseOrderLines(updatedLines);
  };

  const handleGroupInputChange = (categoryIndex, groupLineIndex, field, value, propagateToProducts = true) => {
    const updatedLines = [...purchaseOrderLines];
    const groupLine = updatedLines[categoryIndex].order_lines[groupLineIndex];

    if (!groupLine.isGroup) {
      handleIndividualProductChange(categoryIndex, groupLineIndex, field, value);
      return;
    }

    // Update the group line
    groupLine[field] = value;

    // Calculate quantity_total if quantity or quantity_margin changes
    if (field === 'quantity' || field === 'quantity_margin') {
      const quantity = parseFloat(groupLine.quantity) || 0;
      const margin = parseFloat(groupLine.quantity_margin) || 0;
      groupLine.quantity_total = quantity * (1 + margin / 100);
    }

    // Propagate changes to products if needed
    if (propagateToProducts && groupLine.products) {
      groupLine.products.forEach(product => {
        product[field] = value;
        // Calculate quantity_total for each product
        if (field === 'quantity' || field === 'quantity_margin') {
          const quantity = parseFloat(product.quantity) || 0;
          const margin = parseFloat(product.quantity_margin) || 0;
          product.quantity_total = quantity * (1 + margin / 100);
        }
      });
    }

    setPurchaseOrderLines(updatedLines);
  };

  const toggleCategoryCollapse = (categoryIndex) => {
    setCollapsedCategories(prev => ({
      ...prev,
      [categoryIndex]: !prev[categoryIndex]
    }));
  }

  const handleNotesChange = (categoryIndex, orderLineIndex, notes, productIndex = null) => {
    const updatedLines = [...purchaseOrderLines];

    if (productIndex !== null) {
      // Handle product within a group
      const groupProduct = updatedLines[categoryIndex].order_lines[orderLineIndex].products[productIndex];
      groupProduct.note_client = notes.note_client;
      groupProduct.note_supplier = notes.note_supplier;
    } else {
      // Handle individual product or group
      const orderLine = updatedLines[categoryIndex].order_lines[orderLineIndex];
      orderLine.note_client = notes.note_client;
      orderLine.note_supplier = notes.note_supplier;
    }

    setPurchaseOrderLines(updatedLines);
  };

  const handleStatusChange = (categoryIndex, orderLineIndex, status, productIndex = null) => {
    const updatedLines = [...purchaseOrderLines];

    if (productIndex !== null) {
      // Handle product within a group
      updatedLines[categoryIndex].order_lines[orderLineIndex].products[productIndex].status = status.id;
    } else {
      // Handle individual product or group
      updatedLines[categoryIndex].order_lines[orderLineIndex].status = status.id;
    }

    setPurchaseOrderLines(updatedLines);
  };

  const handleSaveTemplateSubmit = async (templateData) => {
    try {
      // Check if a template with this name already exists
      const existingTemplate = templates.find(t => t.name.toLowerCase() === templateData.name.toLowerCase());

      // Format the purchase order lines for the template
      const formattedPurchaseOrderLines = convertToTemplateFormat(purchaseOrderLines);

      const requestData = {
        name: templateData.name,
        description: templateData.description,
        is_public: templateData.isPublic,
        purchaseOrderLines: formattedPurchaseOrderLines
      };

      // If we found an existing template with the same name, include its ID for update
      if (existingTemplate) {
        requestData.template_id = existingTemplate.id;
      }

      const response = await httpClient.post(
        process.env.REACT_APP_API_URL + `/api/purchase-order-templates/save`,
        requestData
      );

      if (response.status === 200) {
        toast.success(existingTemplate ? "Template updated successfully" : "Template saved successfully");
        setShowSaveTemplateModal(false);
        await fetchTemplates(); // Refresh templates list
        return response; // Return the response for proper Promise handling
      }
    } catch (error) {
      console.error('Error saving template:', error);
      if (error.response?.data?.message) {
        toast.error(`Failed to save template: ${error.response.data.message}`);
      } else if (error.message) {
        toast.error(`Failed to save template: ${error.message}`);
      } else {
        toast.error("Failed to save template: Unknown error");
      }
      throw error; // Re-throw the error for proper Promise rejection
    }
  };

  const handleApplyTemplate = async (template) => {
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL + `/api/purchase-order-templates/apply/${template.id}/${unit.id}`
      );

      if (response.status === 200) {
        if (response.data.purchaseOrderLines && response.data.purchaseOrderLines.length > 0) {
          setPurchaseOrderLines(response.data.purchaseOrderLines);
          toast.success(`Template "${template.name}" applied successfully`);
        } else {
          toast.warning(`Template "${template.name}" was applied but contains no product lines`);
        }
      }
    } catch (error) {
      console.error('Error applying template:', error);
      if (error.response?.data?.message) {
        toast.error(`Failed to apply template: ${error.response.data.message}`);
      } else if (error.message) {
        toast.error(`Failed to apply template: ${error.message}`);
      } else {
        toast.error("Failed to apply template: Unknown error");
      }
    }
  };

  const convertToTemplateFormat = (purchaseOrderLines) => {
    return purchaseOrderLines.map(category => {
      // Filter order lines to only include standard options
      const filteredOrderLines = category.order_lines.filter(line => line.is_standard_option);
      
      return {
        category_id: category.category_id,
        category_name: category.category_name,
        order_lines: filteredOrderLines.map(line => {
          const templateLine = {
            product_id: line.product_id,
            product_name: line.product_name,
            product_brand: line.product_brand,
            quantity: line.quantity || 0,
            quantity_margin: line.quantity_margin || 0,
            quantity_total: line.quantity_total || 0,
            is_chosen_option: line.is_chosen_option || false,
            is_standard_option: line.is_standard_option || false,
            isAlternative: line.isAlternative || false,
            standard_option_id: line.standard_option_id || null,
            to_include_in_device_list: line.to_include_in_device_list || false,
            to_include_in_water_inspection_list: line.to_include_in_water_inspection_list || false,
            is_consumption_water: line.is_consumption_water || false,
            is_rainwater: line.is_rainwater || false,
            discount_percentage: line.discount_percentage || 0,
            note_client: line.note_client || '',
            note_supplier: line.note_supplier || '',
            status: line.status || 'to_start'
          };

          if (line.isGroup) {
            templateLine.isGroup = true;
            templateLine.group_id = line.group_id;
            templateLine.group_name = line.group_name;
            
            // Filter products within groups to only include standard options
            const filteredProducts = line.products.filter(product => product.is_standard_option);
            
            templateLine.products = filteredProducts.map(product => ({
              product_id: product.product_id,
              product_name: product.product_name,
              product_brand: product.product_brand,
              quantity: product.quantity || 0,
              quantity_margin: product.quantity_margin || 0,
              quantity_total: product.quantity_total || 0,
              is_chosen_option: product.is_chosen_option || false,
              is_standard_option: product.is_standard_option || false,
              isAlternative: product.isAlternative || false,
              standard_option_id: product.standard_option_id || null,
              to_include_in_device_list: product.to_include_in_device_list || false,
              to_include_in_water_inspection_list: product.to_include_in_water_inspection_list || false,
              is_consumption_water: product.is_consumption_water || false,
              is_rainwater: product.is_rainwater || false,
              discount_percentage: product.discount_percentage || 0,
              note_client: product.note_client || '',
              note_supplier: product.note_supplier || '',
              status: product.status || 'to_start'
            }));
          }

          return templateLine;
        })
      };
    }).filter(category => category.order_lines.length > 0); // Remove categories with no standard options
  };

  // Function to toggle showing alternative buttons below an order line
  const toggleAlternativeButtons = (categoryIndex, orderLineIndex) => {
    if (showingAlternativeButtons && 
        showingAlternativeButtons.categoryIndex === categoryIndex && 
        showingAlternativeButtons.orderLineIndex === orderLineIndex) {
      // If clicking the same line, close the alternative buttons
      setShowingAlternativeButtons(null);
    } else {
      // Otherwise, show the alternative buttons for this line
      setShowingAlternativeButtons({
        categoryIndex,
        orderLineIndex
      });
    }
  };

  // Add a product as an alternative
  const handleAddAlternativeProduct = (categoryIndex, orderLineIndex, product) => {
    if (!product) return;
    
    const updatedLines = [...purchaseOrderLines];
    const parentLine = updatedLines[categoryIndex].order_lines[orderLineIndex];
    
    // Create the alternative product
    let alternativeProduct = {
      product_id: product.id,
      product_name: product.product,
      product_brand: product.brand,
      quantity: parentLine.quantity || 0,
      quantity_margin: parentLine.quantity_margin || 0,
      quantity_total: parentLine.quantity_total || 0,
      is_chosen_option: false,
      is_standard_option: false,
      standard_option_id: parentLine.isGroup ? parentLine.group_id : parentLine.product_id, // Link to the standard option
      isAlternative: true
    };
    
    // Insert the alternative product right after the original product
    updatedLines[categoryIndex].order_lines.splice(orderLineIndex + 1, 0, alternativeProduct);
    
    setPurchaseOrderLines(updatedLines);
    setShowingAlternativeButtons(null); // Hide the alternative buttons
  };

  // Add a product group as an alternative
  const handleAddAlternativeGroup = (categoryIndex, orderLineIndex, group) => {
    if (!group) return;
    
    const updatedLines = [...purchaseOrderLines];
    const parentLine = updatedLines[categoryIndex].order_lines[orderLineIndex];
    
    // Create alternative group
    let alternativeGroup = {
      isGroup: true,
      group_id: group.id,
      group_name: group.group_name,
      isExpanded: true,
      is_chosen_option: false,
      is_standard_option: false,
      standard_option_id: parentLine.isGroup ? parentLine.group_id : parentLine.product_id,
      isAlternative: true,
      products: group.products.map(product => ({
        product_id: product.id,
        product_name: product.product,
        product_brand: product.brand,
        quantity: parentLine.quantity || 0,
        quantity_margin: parentLine.quantity_margin || 0,
        quantity_total: parentLine.quantity_total || 0,
        isGroupItem: true,
        parent_group_id: group.id,
        isAlternative: true
      }))
    };
    
    // Insert the alternative group right after the original product/group
    updatedLines[categoryIndex].order_lines.splice(orderLineIndex + 1, 0, alternativeGroup);
    
    setPurchaseOrderLines(updatedLines);
    setShowingAlternativeButtons(null); // Hide the alternative buttons
  };

  // Add a function to handle deleting a purchase order line
  const handleDeleteOrderLine = (categoryIndex, orderLineIndex) => {
    const updatedLines = [...purchaseOrderLines];
    const lineToDelete = updatedLines[categoryIndex].order_lines[orderLineIndex];
    
    // Check if we can delete this line based on business rules
    const canDelete = templateMode || !lineToDelete.is_standard_option;
    
    if (!canDelete) {
      toast.error("Standard options can only be deleted in template mode");
      return;
    }
    
    // Remove the line from the array
    updatedLines[categoryIndex].order_lines.splice(orderLineIndex, 1);
    
    // Check if category has no more lines and remove it if empty
    if (updatedLines[categoryIndex].order_lines.length === 0) {
      updatedLines.splice(categoryIndex, 1);
    }
    
    setPurchaseOrderLines(updatedLines);
    toast.success("Item deleted successfully");
  };

  // Add a function to handle deleting a product from a group
  const handleDeleteGroupProduct = (categoryIndex, groupLineIndex, productIndex) => {
    const updatedLines = [...purchaseOrderLines];
    const group = updatedLines[categoryIndex].order_lines[groupLineIndex];
    const productToDelete = group.products[productIndex];
    
    // Check if we can delete this product based on business rules
    const canDelete = templateMode || !productToDelete.is_standard_option;
    
    if (!canDelete) {
      toast.error("Standard options can only be deleted in template mode");
      return;
    }
    
    // Remove the product from the array
    group.products.splice(productIndex, 1);
    
    // If the group has no more products, delete the entire group
    if (group.products.length === 0) {
      updatedLines[categoryIndex].order_lines.splice(groupLineIndex, 1);
      
      // Check if category has no more lines and remove it if empty
      if (updatedLines[categoryIndex].order_lines.length === 0) {
        updatedLines.splice(categoryIndex, 1);
      }
    }
    
    setPurchaseOrderLines(updatedLines);
    toast.success("Item deleted successfully");
  };

  return (
    <div className='popout_body__content_container' id="unit-purchase-section">
      <div className='top_container'>
        <div className='pocs-section'>
          <h2>
            Person of Contact
            <div style={{ display: 'flex', gap: '8px' }}>
              <div className='icon-button'>
                <PopupDropdown
                  title="+"
                  options={contacts}
                  onChange={handleAddPoc}
                  searchBar={true}
                />
              </div>
              {pocs.length > 0 && (
                <button
                  className='icon-button'
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  <MdExpand />
                </button>
              )}
            </div>
          </h2>
          <div className='poc-list' style={{ maxHeight: isExpanded ? 'none' : '25px', overflow: 'hidden' }}>
            {pocs.map((poc, index) => (
              <div className='poc-item' key={index}>
                <div onClick={() => navigate(`/contacts/${poc.id}`)}>
                  {poc.first_name} {poc.last_name}
                </div>
                <div
                  onClick={() => handleCopy(poc.email)}
                  style={{ cursor: 'copy', display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <MdEmail /> {poc.email}
                </div>
                <div
                  onClick={() => handleCopy(poc.mobile_phone)}
                  style={{ cursor: 'copy', display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <FaPhoneAlt /> {poc.mobile_phone}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='purchase-order-buttons'>
          <button
            className='icon-button red'
            title="Toggle template mode"
            onClick={() => setTemplateMode(!templateMode)}
          >
            <GrTemplate />
          </button>

          {templateMode && (
            <>
              <button
                className='icon-button blue'
                title="Save as template"
                onClick={() => setShowSaveTemplateModal(true)}
              >
                <MdSave />
              </button>

              <PopupDropdown
                icon={<MdOutlineCloudDownload />}
                title="Apply template"
                options={templates}
                onChange={handleApplyTemplate}
                searchBar={true}
                padding="0px"
              />
            </>
          )}
        </div>
      </div>
      <div className='bottom_container'>
        <h2>Purchase details</h2>
        {isLoadingPurchaseOrders ? (
          <div className="loading-container">
            <CircularProgress size={50} color="inherit" />
          </div>
        ) : (
          <div className='purchase_details--container'>
            <div className='product_categories'>
              <div className='product_categories--list'>
                {purchaseOrderLines.map((purchaseOrderLine, categoryIndex) => (
                  <div className='product_category--item' key={categoryIndex}>
                    <div
                      className='product_category--title'
                      onClick={() => toggleCategoryCollapse(categoryIndex)}
                      style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                    >
                      {categoryIndex + 1}. {purchaseOrderLine.category_name}
                      {collapsedCategories[categoryIndex] ?
                        <KeyboardArrowRight style={{ marginLeft: '8px', fontSize: 20 }} /> :
                        <KeyboardArrowDown style={{ marginLeft: '8px', fontSize: 20 }} />
                      }
                    </div>
                    {!collapsedCategories[categoryIndex] && (
                      <div className='product_category--scrollable-area'>
                        <div className='product_categories--order_line__header'>
                          <div className='product_categories--order_line__header_name'>Product</div>
                          <div className='product_categories--order_line__header_name'>Quantity</div>
                          <div className='product_categories--order_line__header_name'>Quantity Margin</div>
                          <div className='product_categories--order_line__header_name'>Quantity Total</div>
                          <div className='product_categories--order_line__header_name'>Is Chosen</div>
                          {!templateMode && (
                            <div className='product_categories--order_line__header_name'></div>
                          )}
                          <div className='product_categories--order_line__header_name'>Standard Option</div>
                          {templateMode && (
                            <>
                              <div className='product_categories--order_line__header_name'>Device List</div>
                              <div className='product_categories--order_line__header_name'>Water Inspection</div>
                              <div className='product_categories--order_line__header_name'>Consumption Water</div>
                              <div className='product_categories--order_line__header_name'>Rainwater</div>
                            </>
                          )}
                          <div className='product_categories--order_line__header_name'>Discount %</div>
                          <div className='product_categories--order_line__header_name'>Notes</div>
                          <div className='product_categories--order_line__header_name'>Status</div>
                          <div className='product_categories--order_line__header_name'>Actions</div>
                        </div>
                        <div className='product_category--order_lines'>
                          {purchaseOrderLine.order_lines.map((orderLine, orderLineIndex) => (
                            <React.Fragment key={orderLineIndex}>
                              {orderLine.isGroup ? (
                                <>
                                  <div className="product_group_container">
                                    <div className={`product_category--order_line group-header ${!orderLine.is_standard_option ? 'alternative' : ''}`}>
                                      <div
                                        className='product_category--order_line_name no-border'
                                        onClick={() => {
                                          const updatedLines = [...purchaseOrderLines];
                                          updatedLines[categoryIndex].order_lines[orderLineIndex].isExpanded =
                                            !updatedLines[categoryIndex].order_lines[orderLineIndex].isExpanded;
                                          setPurchaseOrderLines(updatedLines);
                                        }}
                                      >
                                        {categoryIndex + 1}.{orderLineIndex + 1} {orderLine.group_name}
                                        {orderLine.isExpanded ?
                                          <KeyboardArrowDown id="group-header-arrow" sx={{ fontSize: 20 }} /> :
                                          <KeyboardArrowRight id="group-header-arrow" sx={{ fontSize: 20 }} />
                                        }
                                      </div>

                                      <div className='product_category--order_line_name'>
                                        <NumericFormat
                                          value={orderLine.quantity}
                                          onValueChange={(values) => handleGroupInputChange(
                                            categoryIndex,
                                            orderLineIndex,
                                            'quantity',
                                            values.value,
                                            true
                                          )}
                                          className="order-line-input"
                                        />
                                      </div>

                                      <div className='product_category--order_line_name'>
                                        <NumericFormat
                                          value={orderLine.quantity_margin}
                                          suffix="%"
                                          onValueChange={(values) => handleGroupInputChange(
                                            categoryIndex,
                                            orderLineIndex,
                                            'quantity_margin',
                                            values.value,
                                            false
                                          )}
                                          className="order-line-input"
                                        />
                                      </div>

                                      <div className='product_category--order_line_name'>
                                        <NumericFormat
                                          value={orderLine.quantity_total}
                                          displayType="input"
                                          decimalScale={2}
                                          readOnly
                                          className="order-line-input"
                                        />
                                      </div>

                                      <div className='product_category--order_line_name'>
                                        <CheckBox
                                          margin={"5px 0 5px 25%"}
                                          state={orderLine.is_chosen_option}
                                          setState={(value) => handleGroupInputChange(
                                            categoryIndex,
                                            orderLineIndex,
                                            'is_chosen_option',
                                            value,
                                            true
                                          )}
                                        />
                                      </div>

                                      {!templateMode && (
                                        <div className='product_category--order_line_name'>
                                          <button 
                                            className={`alternative-button ${orderLine.is_chosen_option || !orderLine.is_standard_option ? "hide" : ""}`}
                                            onClick={() => toggleAlternativeButtons(categoryIndex, orderLineIndex)}
                                          >
                                            <IoAddCircleOutline />
                                            Alternative
                                          </button>
                                        </div>
                                      )}

                                      <div className='product_category--order_line_name'>
                                        <CheckBox
                                          margin={"5px 0 5px 25%"}
                                          state={orderLine.is_standard_option}
                                          setState={(value) => handleGroupInputChange(
                                            categoryIndex,
                                            orderLineIndex,
                                            'is_standard_option',
                                            value,
                                            true
                                          )}
                                          disabled={!templateMode}
                                        />
                                      </div>

                                      {templateMode && (
                                        <>
                                          <div className='product_category--order_line_name'>
                                            <CheckBox
                                              margin={"5px 0 5px 25%"}
                                              state={orderLine.to_include_in_device_list}
                                              setState={(value) => handleGroupInputChange(
                                                categoryIndex,
                                                orderLineIndex,
                                                'to_include_in_device_list',
                                                value,
                                                true
                                              )}
                                              disabled={!templateMode}
                                            />
                                          </div>

                                          <div className='product_category--order_line_name'>
                                            <CheckBox
                                              margin={"5px 0 5px 25%"}
                                              state={orderLine.to_include_in_water_inspection_list}
                                              setState={(value) => handleGroupInputChange(
                                                categoryIndex,
                                                orderLineIndex,
                                                'to_include_in_water_inspection_list',
                                                value,
                                                true
                                              )}
                                              disabled={!templateMode}
                                            />
                                          </div>

                                          <div className='product_category--order_line_name'>
                                            <CheckBox
                                              margin={"5px 0 5px 25%"}
                                              state={orderLine.is_consumption_water}
                                              setState={(value) => handleGroupInputChange(
                                                categoryIndex,
                                                orderLineIndex,
                                                'is_consumption_water',
                                                value,
                                                true
                                              )}
                                              disabled={!templateMode}
                                            />
                                          </div>

                                          <div className='product_category--order_line_name'>
                                            <CheckBox
                                              margin={"5px 0 5px 25%"}
                                              state={orderLine.is_rainwater}
                                              setState={(value) => handleGroupInputChange(
                                                categoryIndex,
                                                orderLineIndex,
                                                'is_rainwater',
                                                value,
                                                true
                                              )}
                                              disabled={!templateMode}
                                            />
                                          </div>
                                        </>
                                      )}

                                      <div className='product_category--order_line_name'>
                                        <NumericFormat
                                          value={orderLine.discount_percentage}
                                          suffix="%"
                                          onValueChange={(values) => handleGroupInputChange(
                                            categoryIndex,
                                            orderLineIndex,
                                            'discount_percentage',
                                            values.value,
                                            true
                                          )}
                                          className="order-line-input"
                                        />
                                      </div>

                                      <div className='product_category--order_line_name'>
                                        <PopupInput
                                          title="Notes"
                                          fields={[
                                            { id: 'note_client', label: 'Client Note', placeholder: 'Add note for client...' },
                                            { id: 'note_supplier', label: 'Supplier Note', placeholder: 'Add note for supplier...' }
                                          ]}
                                          values={{
                                            note_client: orderLine.note_client || '',
                                            note_supplier: orderLine.note_supplier || ''
                                          }}
                                          onChange={(notes) => handleNotesChange(categoryIndex, orderLineIndex, notes)}
                                          width="700px"
                                          height="auto"
                                        />
                                      </div>
                                      <div className='product_category--order_line_name'>
                                        <PopupDropdown
                                          title="Set status"
                                          options={statusOptions}
                                          value={orderLine.status ? statusOptions.find(s => s.id === orderLine.status) : null}
                                          onChange={(status) => handleStatusChange(categoryIndex, orderLineIndex, status)}
                                          labelView={true}
                                          labelColors={statusOptions.reduce((acc, option) => {
                                            acc[option.id] = statusLabelColors[option.id];
                                            return acc;
                                          }, {})}
                                          padding="0px"
                                        />
                                      </div>
                                      {/* Add delete button for group header */}
                                      <div className='product_category--order_line_name'>
                                        {(templateMode || !orderLine.is_standard_option) && (
                                          <button 
                                            className='icon-button delete-button'
                                            onClick={() => handleDeleteOrderLine(categoryIndex, orderLineIndex)}
                                            title="Delete group"
                                          >
                                            <MdDelete />
                                          </button>
                                        )}
                                      </div>
                                    </div>

                                    {orderLine.isExpanded && orderLine.products.map((groupProduct, productIndex) => (
                                      <div className='product_category--order_line indented' key={`${orderLineIndex}-${productIndex}`}>
                                        <div className='product_category--order_line_name no-border'>
                                          {categoryIndex + 1}.{orderLineIndex + 1}.{productIndex + 1} {groupProduct.product_brand} - {groupProduct.product_name}
                                        </div>
                                        <div className='product_category--order_line_name'>
                                          <NumericFormat
                                            value={groupProduct.quantity}
                                            onValueChange={(values) => handleIndividualProductChange(
                                              categoryIndex,
                                              orderLineIndex,
                                              'quantity',
                                              values.value,
                                              productIndex
                                            )}
                                            className="order-line-input"
                                          />
                                        </div>
                                        <div className='product_category--order_line_name'>
                                          <NumericFormat
                                            value={groupProduct.quantity_margin}
                                            suffix="%"
                                            onValueChange={(values) => handleIndividualProductChange(
                                              categoryIndex,
                                              orderLineIndex,
                                              'quantity_margin',
                                              values.value,
                                              productIndex
                                            )}
                                            className="order-line-input"
                                          />
                                        </div>
                                        <div className='product_category--order_line_name'>
                                          <NumericFormat
                                            value={groupProduct.quantity_total}
                                            displayType="input"
                                            decimalScale={2}
                                            readOnly
                                            className="order-line-input"
                                          />
                                        </div>
                                        <div className='product_category--order_line_name'>
                                          <CheckBox
                                            margin={"5px 0 5px 25%"}
                                            state={orderLine.is_chosen_option}
                                            setState={(value) => handleGroupInputChange(
                                              categoryIndex,
                                              orderLineIndex,
                                              'is_chosen_option',
                                              value,
                                              true
                                            )}
                                          />
                                        </div>
                                        <div className='product_category--order_line_name'></div>
                                        <div className='product_category--order_line_name'>
                                          <CheckBox
                                            margin={"5px 0 5px 25%"}
                                            state={groupProduct.is_standard_option}
                                            setState={(value) => {
                                              const updatedLines = [...purchaseOrderLines];
                                              updatedLines[categoryIndex].order_lines[orderLineIndex].products[productIndex].is_standard_option = value;
                                              setPurchaseOrderLines(updatedLines);
                                            }}
                                            disabled={!templateMode}
                                          />
                                        </div>
                                        {templateMode && (
                                          <>
                                            <div className='product_category--order_line_name'>
                                              <CheckBox
                                                margin={"5px 0 5px 25%"}
                                                state={groupProduct.to_include_in_device_list}
                                                setState={(value) => {
                                                  const updatedLines = [...purchaseOrderLines];
                                                  updatedLines[categoryIndex].order_lines[orderLineIndex].products[productIndex].to_include_in_device_list = value;
                                                  setPurchaseOrderLines(updatedLines);
                                                }}
                                                disabled={!templateMode}
                                              />
                                            </div>
                                            <div className='product_category--order_line_name'>
                                              <CheckBox
                                                margin={"5px 0 5px 25%"}
                                                state={groupProduct.to_include_in_water_inspection_list}
                                                setState={(value) => {
                                                  const updatedLines = [...purchaseOrderLines];
                                                  updatedLines[categoryIndex].order_lines[orderLineIndex].products[productIndex].to_include_in_water_inspection_list = value;
                                                  setPurchaseOrderLines(updatedLines);
                                                }}
                                                disabled={!templateMode}
                                              />
                                            </div>
                                            <div className='product_category--order_line_name'>
                                              <CheckBox
                                                margin={"5px 0 5px 25%"}
                                                state={groupProduct.is_consumption_water}
                                                setState={(value) => {
                                                  const updatedLines = [...purchaseOrderLines];
                                                  updatedLines[categoryIndex].order_lines[orderLineIndex].products[productIndex].is_consumption_water = value;
                                                  setPurchaseOrderLines(updatedLines);
                                                }}
                                                disabled={!templateMode}
                                              />
                                            </div>
                                            <div className='product_category--order_line_name'>
                                              <CheckBox
                                                margin={"5px 0 5px 25%"}
                                                state={groupProduct.is_rainwater}
                                                setState={(value) => {
                                                  const updatedLines = [...purchaseOrderLines];
                                                  updatedLines[categoryIndex].order_lines[orderLineIndex].products[productIndex].is_rainwater = value;
                                                  setPurchaseOrderLines(updatedLines);
                                                }}
                                                disabled={!templateMode}
                                              />
                                            </div>
                                          </>
                                        )}
                                        <div className='product_category--order_line_name'>
                                          <NumericFormat
                                            value={groupProduct.discount_percentage}
                                            suffix="%"
                                            onValueChange={(values) => handleIndividualProductChange(
                                              categoryIndex,
                                              orderLineIndex,
                                              'discount_percentage',
                                              values.value,
                                              productIndex
                                            )}
                                            className="order-line-input"
                                          />
                                        </div>
                                        <div className='product_category--order_line_name'>
                                          <PopupInput
                                            title="Notes"
                                            fields={[
                                              { id: 'note_client', label: 'Client Note', placeholder: 'Add note for client...' },
                                              { id: 'note_supplier', label: 'Supplier Note', placeholder: 'Add note for supplier...' }
                                            ]}
                                            values={{
                                              note_client: groupProduct.note_client || '',
                                              note_supplier: groupProduct.note_supplier || ''
                                            }}
                                            onChange={(notes) => handleNotesChange(categoryIndex, orderLineIndex, notes, productIndex)}
                                            width="700px"
                                            height="auto"
                                          />
                                        </div>
                                        <div className='product_category--order_line_name'>
                                          <PopupDropdown
                                            title="Set status"
                                            options={statusOptions}
                                            value={groupProduct.status ? statusOptions.find(s => s.id === groupProduct.status) : null}
                                            onChange={(status) => handleStatusChange(categoryIndex, orderLineIndex, status, productIndex)}
                                            labelView={true}
                                            labelColors={statusOptions.reduce((acc, option) => {
                                              acc[option.id] = statusLabelColors[option.id];
                                              return acc;
                                            }, {})}
                                            padding="0px"
                                          />
                                        </div>
                                        {/* Add delete button for group products */}
                                        <div className='product_category--order_line_name'>
                                          {(templateMode || !groupProduct.is_standard_option) && (
                                            <button 
                                              className='icon-button delete-button'
                                              onClick={() => handleDeleteGroupProduct(categoryIndex, orderLineIndex, productIndex)}
                                              title="Delete product"
                                            >
                                              <MdDelete />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                  {/* Show alternative options for groups */}
                                  {showingAlternativeButtons && 
                                   showingAlternativeButtons.categoryIndex === categoryIndex && 
                                   showingAlternativeButtons.orderLineIndex === orderLineIndex && (
                                    <div className='alternative-options-container'>
                                      <div className='alternative-options'>
                                        <div className='alternative-option'>
                                          <PopupDropdown
                                            width="500px"
                                            title="Add Product Alternative"
                                            options={products}
                                            onChange={(product) => {
                                              handleAddAlternativeProduct(categoryIndex, orderLineIndex, product);
                                            }}
                                            searchBar={true}
                                            customButton={
                                              <button className='alternative-option-button product'>
                                                <IoAddCircleOutline />
                                                Add Product Alternative
                                              </button>
                                            }
                                          />
                                        </div>
                                        <div className='alternative-option'>
                                          <PopupDropdown
                                            width="500px"
                                            title="Add Group Alternative"
                                            options={productGroups}
                                            onChange={(group) => {
                                              handleAddAlternativeGroup(categoryIndex, orderLineIndex, group);
                                            }}
                                            searchBar={true}
                                            excludeOptions={["id", "products", "group_description"]}
                                            customButton={
                                              <button className='alternative-option-button group'>
                                                <IoAddCircleOutline />
                                                Add Group Alternative
                                              </button>
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className={`product_category--order_line ${!orderLine.is_standard_option ? 'alternative' : ''}`}>
                                    <div className='product_category--order_line_name no-border'>
                                      {categoryIndex + 1}.{orderLineIndex + 1} {orderLine.product_brand} - {orderLine.product_name}
                                    </div>
                                    <div className='product_category--order_line_name'>
                                      <NumericFormat
                                        value={orderLine.quantity}
                                        onValueChange={(values) => handleIndividualProductChange(
                                          categoryIndex,
                                          orderLineIndex,
                                          'quantity',
                                          values.value
                                        )}
                                        className="order-line-input"
                                      />
                                    </div>
                                    <div className='product_category--order_line_name'>
                                      <NumericFormat
                                        value={orderLine.quantity_margin}
                                        suffix="%"
                                        onValueChange={(values) => handleIndividualProductChange(
                                          categoryIndex,
                                          orderLineIndex,
                                          'quantity_margin',
                                          values.value
                                        )}
                                        className="order-line-input"
                                      />
                                    </div>
                                    <div className='product_category--order_line_name'>
                                      <NumericFormat
                                        value={orderLine.quantity_total}
                                        displayType="input"
                                        decimalScale={2}
                                        readOnly
                                        className="order-line-input"
                                      />
                                    </div>
                                    <div className='product_category--order_line_name'>
                                      <CheckBox
                                        margin={"5px 0 5px 25%"}
                                        state={orderLine.is_chosen_option}
                                        setState={(value) => handleGroupInputChange(
                                          categoryIndex,
                                          orderLineIndex,
                                          'is_chosen_option',
                                          value,
                                          true
                                        )}
                                      />
                                    </div>
                                    {!templateMode && (
                                      <div className='product_category--order_line_name'>
                                        <button 
                                          className={`alternative-button ${orderLine.is_chosen_option || !orderLine.is_standard_option ? "hide" : ""}`}
                                          onClick={() => toggleAlternativeButtons(categoryIndex, orderLineIndex)}
                                        >
                                          <IoAddCircleOutline />
                                          Alternative
                                        </button>
                                      </div>
                                    )}
                                    <div className='product_category--order_line_name centered'>
                                      <CheckBox
                                        margin={"5px 0 5px 25%"}
                                        state={orderLine.is_standard_option}
                                        setState={(value) => {
                                          const updatedLines = [...purchaseOrderLines];
                                          updatedLines[categoryIndex].order_lines[orderLineIndex].is_standard_option = value;
                                          setPurchaseOrderLines(updatedLines);
                                        }}
                                        disabled={!templateMode}
                                      />
                                    </div>
                                    {templateMode && (
                                      <>
                                        <div className='product_category--order_line_name'>
                                          <CheckBox
                                            margin={"5px 0 5px 25%"}
                                            state={orderLine.to_include_in_device_list}
                                            setState={(value) => {
                                              const updatedLines = [...purchaseOrderLines];
                                              updatedLines[categoryIndex].order_lines[orderLineIndex].to_include_in_device_list = value;
                                              setPurchaseOrderLines(updatedLines);
                                            }}
                                            disabled={!templateMode}
                                          />
                                        </div>
                                        <div className='product_category--order_line_name'>
                                          <CheckBox
                                            margin={"5px 0 5px 25%"}
                                            state={orderLine.to_include_in_water_inspection_list}
                                            setState={(value) => {
                                              const updatedLines = [...purchaseOrderLines];
                                              updatedLines[categoryIndex].order_lines[orderLineIndex].to_include_in_water_inspection_list = value;
                                              setPurchaseOrderLines(updatedLines);
                                            }}
                                            disabled={!templateMode}
                                          />
                                        </div>
                                        <div className='product_category--order_line_name'>
                                          <CheckBox
                                            margin={"5px 0 5px 25%"}
                                            state={orderLine.is_consumption_water}
                                            setState={(value) => {
                                              const updatedLines = [...purchaseOrderLines];
                                              updatedLines[categoryIndex].order_lines[orderLineIndex].is_consumption_water = value;
                                              setPurchaseOrderLines(updatedLines);
                                            }}
                                            disabled={!templateMode}
                                          />
                                        </div>
                                        <div className='product_category--order_line_name'>
                                          <CheckBox
                                            margin={"5px 0 5px 25%"}
                                            state={orderLine.is_rainwater}
                                            setState={(value) => {
                                              const updatedLines = [...purchaseOrderLines];
                                              updatedLines[categoryIndex].order_lines[orderLineIndex].is_rainwater = value;
                                              setPurchaseOrderLines(updatedLines);
                                            }}
                                            disabled={!templateMode}
                                          />
                                        </div>
                                      </>
                                    )}
                                    <div className='product_category--order_line_name'>
                                      <NumericFormat
                                        value={orderLine.discount_percentage}
                                        suffix="%"
                                        onValueChange={(values) => handleIndividualProductChange(
                                          categoryIndex,
                                          orderLineIndex,
                                          'discount_percentage',
                                          values.value
                                        )}
                                        className="order-line-input"
                                      />
                                    </div>
                                    <div className='product_category--order_line_name'>
                                      <PopupInput
                                        title="Notes"
                                        fields={[
                                          { id: 'note_client', label: 'Client Note', placeholder: 'Add note for client...' },
                                          { id: 'note_supplier', label: 'Supplier Note', placeholder: 'Add note for supplier...' }
                                        ]}
                                        values={{
                                          note_client: orderLine.note_client || '',
                                          note_supplier: orderLine.note_supplier || ''
                                        }}
                                        onChange={(notes) => handleNotesChange(categoryIndex, orderLineIndex, notes)}
                                        width="700px"
                                        height="auto"
                                      />
                                    </div>
                                    <div className='product_category--order_line_name'>
                                      <PopupDropdown
                                        title="Set status"
                                        options={statusOptions}
                                        value={orderLine.status ? statusOptions.find(s => s.id === orderLine.status) : null}
                                        onChange={(status) => handleStatusChange(categoryIndex, orderLineIndex, status)}
                                        labelView={true}
                                        labelColors={statusOptions.reduce((acc, option) => {
                                          acc[option.id] = statusLabelColors[option.id];
                                          return acc;
                                        }, {})}
                                        padding="0px"
                                      />
                                    </div>
                                    {/* Add delete button for individual products */}
                                    <div className='product_category--order_line_name'>
                                      {(templateMode || !orderLine.is_standard_option) && (
                                        <button 
                                          className='icon-button delete-button'
                                          onClick={() => handleDeleteOrderLine(categoryIndex, orderLineIndex)}
                                          title="Delete product"
                                        >
                                          <MdDelete />
                                        </button>
                                      )}
                                    </div>
                                  </div>

                                  {/* Show alternative options for products */}
                                  {showingAlternativeButtons && 
                                   showingAlternativeButtons.categoryIndex === categoryIndex && 
                                   showingAlternativeButtons.orderLineIndex === orderLineIndex && (
                                    <div className='alternative-options-container'>
                                      <div className='alternative-options'>
                                        <div className='alternative-option'>
                                          <PopupDropdown
                                            width="500px"
                                            title="Add Product Alternative"
                                            options={products}
                                            onChange={(product) => {
                                              handleAddAlternativeProduct(categoryIndex, orderLineIndex, product);
                                            }}
                                            searchBar={true}
                                          />
                                        </div>
                                        <div className='alternative-option'>
                                          <PopupDropdown
                                            width="500px"
                                            title="Add Group Alternative"
                                            options={productGroups}
                                            onChange={(group) => {
                                              handleAddAlternativeGroup(categoryIndex, orderLineIndex, group);
                                            }}
                                            searchBar={true}
                                            excludeOptions={["id", "products", "group_description"]}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                        <div className='product_category--buttons'>
                          <div className={`po_add_button ${templateMode ? "" : "hide"}`}>
                            <PopupDropdown
                              width="500px"
                              title="Add product"
                              options={products}
                              onChange={(product) => handleAddProduct("product", categoryIndex, product)}
                              searchBar={true}
                            />
                          </div>
                          <div className={`po_add_button ${templateMode ? "" : "hide"}`}>
                            <PopupDropdown
                              width="500px"
                              title="Add group"
                              options={productGroups}
                              onChange={(productGroup) => handleAddProduct("group", categoryIndex, productGroup)}
                              searchBar={true}
                              excludeOptions={["id", "products", "group_description"]}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className={`po_add_button ${templateMode ? "" : "hide"}`}>
                <PopupDropdown
                  title="Add product category"
                  options={productCategoryList}
                  onChange={handleAddProductCategory}
                  searchBar={true}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <TemplateModal
        isOpen={showSaveTemplateModal}
        onClose={() => setShowSaveTemplateModal(false)}
        onSave={handleSaveTemplateSubmit}
        initialIsPublic={true}
        templates={templates}
      />
    </div>
  )
})

export default PurchaseSection 
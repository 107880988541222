import React, { useState, useEffect } from 'react'
import "./TransactionsPage.css"
import Header from '../components/Header'
import MenuBar from '../components/MenuBar'
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import httpClient from '../components/helpers/httpClient'
import { PopupWindowTransaction } from '../components/PopupWindowTransaction'
import BuyIcon from '../assets/pictos/svg/BuyIcon'
import SellIcon from '../assets/pictos/svg/SellIcon'
import OriginIcon from '../assets/pictos/svg/OriginIcon'
import { formatDate } from '../components/helpers/formatDate'
import { formatThousands } from '../components/helpers/formatNumbers'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import DropDownFilter from '../components/DropDownFilter'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilters, updateTransactionsList, resetStateTransactionsPage } from '../store/transactionsPage'

function TransactionsPage() {
  const [loading, setLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(20)
  const [popupTransactionActive, setPopupTransactionActive] = useState(false)
  const [transactionActionType, setTransactionActionType] = useState("create")
  const [selectedTransactionID, setSelectedTransactionID] = useState(null)

  const dispatch = useDispatch()

  const filters = useSelector(state => state.transactionsPage.filtersTransactions)
  const transactions = useSelector(state => state.transactionsPage.transactionsList)

  const fetchTransactions = async () => {
    setLoading(true)

    const data = {
      filters: filters
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/transactions/get?page=${filters.page}&limit=${limit}`, data)
    dispatch(updateTransactionsList(response.data))
    setLoading(false)
  }

  useEffect(() => {
    fetchTransactions()
  }, [])

  useEffect(() => {
    fetchTransactions()
  }, [filters])

  const handleOnClickRow = (transactionID) => {
    setTransactionActionType("edit")
    setSelectedTransactionID(transactionID)
    setPopupTransactionActive(true)
  }

  const handleSetSortFilter = (sortType) => {
    console.log(sortType)
  }

  const handlePagination = (type) => {
    console.log(type)
  }

  const handleClearSearchBar = () => {
    dispatch(updateFilters({ ...filters, searchWord: "" }))
  }

  const handleSearchWord = (e) => {
    dispatch(updateFilters({ ...filters, searchWord: e.target.value }))
  }

  const handleOnClickDropdownItem = (event, value, idList) => {
    dispatch(updateFilters({ ...filters, typeFilter: value }))
  }

  return (
    <div className='template'>
      <Header>
        <div className="header__filters" id="company__table-btns">
          <div className="search-bar" id="companies__search-bar--table" onClick={handleClearSearchBar}>
            <input placeholder="Search transaction..." value={filters.searchWord} onChange={handleSearchWord} />
            {filters.searchWord == "" ? <SearchIcon /> : <ClearIcon />}
          </div>
          <DropDownFilter
            idList="transactions__dropdownList--type"
            placeholderText="Filter on type"
            listArray={["Aankoop", "Verkoop", "Oorsprong"]}
            onClickItem={handleOnClickDropdownItem}
            filters={filters}
            value={filters.typeFilter}
            filter={"typeFilter"}
            setValue={updateFilters}
          />
        </div>
      </Header>

      <MenuBar />

      <PopupWindowTransaction
        overflow="hidden"
        isActive={popupTransactionActive}
        setIsActive={setPopupTransactionActive}
        transactionActionType={transactionActionType}
        selectedTransactionID={selectedTransactionID}
        fetchTransactions={fetchTransactions}
      />

      <div className="template__body" id="body-transactions">
        <button className="floating__button--bottom-right" id="add--btn" onClick={() => { setPopupTransactionActive(true); setTransactionActionType("create") }} />
        <div className='body__section big' id='all-companies-table'>
          <div className='table'>
            <div className='table__header-5'>
              <div className='table__header--field' onClick={() => handleSetSortFilter("type")}></div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("reservation_date")}>Contract date</div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("cadastral_nrs")}>Cadastral nr(s)</div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("project_names")}>Project Name(s)</div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("transaction_price")}>€ Price</div>
            </div>
            <div className='table__rows_container'>

              {
                loading ?
                  <LoadingSpinner /> :
                  transactions.map((transaction, index) => {
                    return (
                      <div
                        className={`table__row-5 ${transaction.type == "Aankoop" ? "buy" : transaction.type == "Verkoop" ? "sell" : "origin"}`}
                        key={index}
                        onClick={() => handleOnClickRow(transaction.id)}
                        data-transaction-id={transaction.id}
                      >
                        <div className='table__row--field'>
                          {transaction.type == "Aankoop" ?
                            <div className="table__row--field--icon buy">
                              <BuyIcon />
                            </div> :
                            transaction.type == "Verkoop" ?
                              <div className="table__row--field--icon sell">
                                <SellIcon />
                              </div> :
                              <div className="table__row--field--icon origin">
                                <OriginIcon />
                              </div>}
                        </div>
                        <div className='table__row--field'>{transaction.contract_date ? formatDate(transaction.contract_date) : ""}</div>
                        <div className='table__row--field'>
                          {Array.isArray(transaction.cadastral_nrs) &&
                            <div className='labels-container'>
                              {transaction.cadastral_nrs.map((nr, i) => (
                                <span key={i} className="label-item">{nr}</span>
                              ))}
                            </div>
                          }
                        </div>
                        <div className='table__row--field'>
                          {Array.isArray(transaction.project_names) &&
                            <div className='labels-container'>
                              {transaction.project_names.map((name, i) => (
                                <span key={i} className="label-item">{name}</span>
                              ))}
                            </div>
                          }
                        </div>
                        <div className='table__row--field'>€ {formatThousands(transaction.transaction_price)}</div>
                      </div>
                    )
                  })
              }
            </div>
            <div className="pagination">
              <ArrowBackIosIcon className={`pagination__btn ${filters.page == 1 ? "grayed-out" : ""}`} onClick={() => handlePagination("previous")} data-type="previous" id="prevPage" />
              <ArrowForwardIosIcon className={`pagination__btn ${filters.page == totalPages ? "grayed-out" : ""}`} onClick={() => handlePagination("next")} data-type="next" id="nextPage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionsPage
import React, { useState, useEffect } from 'react'
import "./CheckBox.css"

function CheckBox({ 
  width = "100%", 
  height = "30px", 
  textPosition = "left", 
  state, 
  text,
  textColor = "black",
  textSize = "0.7rem",
  setData, 
  setState, 
  margin = "5px 0px", 
  padding = "0px", 
  marginLeft, 
  disabled = false,
  darkMode = false
}) {
  const [isChecked, setIsChecked] = useState(state)

  useEffect(() => {
    setIsChecked(state)
  }, [state])

  const handleOnClickCheckbox = () => {
    if (disabled) return;
    
    setState(!state)
    try {
      setData()
    }
    catch (error) {
      console.log("No data to set")
    }
  }

  return (
    <div 
      className={`checkbox__container ${disabled ? 'checkbox--disabled' : ''} ${darkMode ? 'checkbox--dark-mode' : ''}`} 
      onClick={handleOnClickCheckbox} 
      style={{width, margin, padding}}
    >
      {
        textPosition === "left" & text != undefined ? <p style={{ marginRight: "auto", color: textColor, fontSize: textSize }} className="checkbox--text">{text}</p> : null
      }
      <div className="checkbox--box__container">
        <div className={isChecked ? "checkbox--box checked" : "checkbox--box"} style={{ "width": height, "height": height }}>
          <span className={isChecked ? "checkmark checked" : "checkmark"} >
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </div>
      </div>
      {
        textPosition === "right" & text != undefined ? <p style={{ marginLeft: "auto", color: textColor, fontSize: textSize }} className="checkbox--text">{text}</p> : null
      }
    </div>
  )
}

export default CheckBox


import React, { useState, useEffect } from 'react'
import './DashboardPage.css'
import MenuBar from "../components/MenuBar"
import Header from "../components/Header"

function Dashboard() {

    useEffect(() => {
        const header = document.querySelector("#header-title")
        header.classList.remove("hide")
    }, [])

    return (
        <div className='template'>

            <Header>
            </Header>

            <MenuBar />

            <div className="template__body" id='body-dashboard'>
                <div className='body_section'></div>
                <div className='body_section'></div>
                <div className='body_section'></div>
                <div className='body_section'></div>
                <div className='body_section'></div>
            </div>

        </div>
    )
}

export default Dashboard
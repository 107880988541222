import React, { useState, useEffect, useRef } from 'react'
import './PopupWindowUnitDetail.css'
import httpClient from '../helpers/httpClient'
import { toast } from "react-toastify"
import { PopupWindow } from '../PopupWindow'
import GeneralSection from './components/GeneralSection'
import PropertySection from './components/PropertySection'
import RentalSection from './components/RentalSection'
import PurchaseSection from './components/PurchaseSection'
import PriceSection from './components/PriceSection'

function PopupWindowUnitDetail({ isActive, setIsActive, actionType, projectID, selectedItem, setSelectedItem, project, selectedItemID, fetchProject, contactsList, selectedBuilding }) {
  const initialUnitState = {
    unit_name: '',
    address: '',
    building_id: '',
    status: '',
    type: ''
  }

  const [unit, setUnit] = useState(null)
  const [menuItem, setMenuItem] = useState("general")
  const [loading, setLoading] = useState({
    general: true,
    properties: false
  })
  const [creatingNote, setCreatingNote] = useState(false)
  const [creatingProperty, setCreatingProperty] = useState(false)
  const [purchaseOrderTemplateMode, setPurchaseOrderTemplateMode] = useState(false)

  const purchaseSectionRef = useRef(null)
  const generalSectionRef = useRef()
  const priceSectionRef = useRef(null)
  const propertySectionRef = useRef(null)

  useEffect(() => {
    const popup = document.getElementById('popup-unit-detail')
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  useEffect(() => {
    setUnit({ ...initialUnitState, ...selectedItem })
  }, [isActive])

  const closePopupWindow = () => {
    setIsActive(false)
    setUnit(initialUnitState)
    setMenuItem("general")
  }

  const handleSave = async () => {
    if (creatingNote) {
      const success = await generalSectionRef.current.handleCreateNote()
      return
    }

    if (creatingProperty) {
      const success = await propertySectionRef.current.handleCreateProperty()
      return
    }

    if (!unit.unit_name) {
      toast.error("Name is obligatory")
      return
    }

    const saveUnit = httpClient.post(
      process.env.REACT_APP_API_URL + `/api/units/create?itemID=${selectedItemID}&actionType=${actionType}`,
      unit
    )

    let savePurchaseOrders = Promise.resolve()
    if (purchaseSectionRef.current) {
      savePurchaseOrders = purchaseSectionRef.current.handleSavePurchaseOrderLines()
    }

    let savePriceDetails = Promise.resolve()
    if (priceSectionRef.current) {
      savePriceDetails = priceSectionRef.current.handleSavePriceDetails()
    }

    await toast.promise(
      Promise.all([saveUnit, savePurchaseOrders, savePriceDetails]),
      {
        success: `Unit has been ${actionType == "add" ? "created" : "updated"}!`,
        error: "Something went wrong",
        pending: `${actionType == "add" ? "creating" : "updating"} unit...`
      }
    )

    fetchProject()
  }

  const handleSetMenuItem = (menuItem) => {
    setMenuItem(menuItem)
    setCreatingProperty(false)
    setCreatingNote(false)
  }

  return (
    <div
      className={`popup__container unit-detail overlay no-scrollbar ${isActive ? "active" : ""}`}
      id='popup-unit-detail'
      onKeyDown={e => e.key === "Escape" && closePopupWindow()}
    >
      <div className='popup__box no-scrollbar' onClick={e => e.stopPropagation()}>
        <div className='close_button' onClick={closePopupWindow}>x</div>
        <div className='popout_body' id='popout-body-unit'>
          <div className='popout_window__header'>
            <button className='primary-button-header' onClick={handleSave}>
              {creatingNote ? 
              "CREATE NOTE" : 
              creatingProperty ?
              "CREATE KI" :
              "SAVE"}
            </button>
            <div className='popout_window__header--title'>
              <div>{project.project_commercial_name ? project.project_commercial_name : project.project_placeholder_name}</div>
              <div>|</div>
              <div>{selectedBuilding?.building_name ? selectedBuilding.building_name : ""}</div>
              <div>|</div>
              <div>{unit?.unit_name ? unit.unit_name : ""}</div>
            </div>
          </div>

          <div className='menu__titles'>
            <h3 className={`${menuItem == "general" ? "selected" : ""}`} onClick={() => setMenuItem("general")}>general</h3>
            {actionType != "add" && (
              <>
                <h3 className={`${menuItem == "price" ? "selected" : ""}`} onClick={() => handleSetMenuItem("price")}>price details</h3>
                <h3 className={`${menuItem == "property" ? "selected" : ""}`} onClick={() => handleSetMenuItem("property")}>property details</h3>
                <h3 className={`${menuItem == "rental" ? "selected" : ""}`} onClick={() => handleSetMenuItem("rental")}>rental details</h3>
                <h3 className={`${menuItem == "purchase" ? "selected" : ""}`} onClick={() => handleSetMenuItem("purchase")}>purchase orders</h3>
              </>
            )}
          </div>

          {menuItem == "general" && (
            <GeneralSection
              ref={generalSectionRef}
              unit={unit}
              setUnit={setUnit}
              actionType={actionType}
              contactsList={contactsList}
              loading={loading}
              creatingNote={creatingNote}
              setCreatingNote={setCreatingNote}
            />
          )}

          {menuItem == "property" && (
            <PropertySection
              ref={propertySectionRef}
              unit={unit}
              loading={loading}
              creatingProperty={creatingProperty}
              setCreatingProperty={setCreatingProperty}
            />
          )}

          {menuItem == "rental" && (
            <RentalSection />
          )}

          {menuItem == "price" && (
            <PriceSection
              ref={priceSectionRef}
              unit={unit}
              loading={loading}
            />
          )}

          {menuItem == "purchase" && (
            <PurchaseSection
              ref={purchaseSectionRef}
              unit={unit}
              purchaseOrderTemplateMode={purchaseOrderTemplateMode}
              setPurchaseOrderTemplateMode={setPurchaseOrderTemplateMode}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PopupWindowUnitDetail 
import React, { useState, useEffect, useRef } from 'react'
import './DropdownMultiselectTags.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import httpClient from './helpers/httpClient'
import { MdOutlineDeleteSweep } from "react-icons/md"

function DropdownMultiselectTags({ fontStyle, state, marginTop, fontSize, setState, width, idList, index }) {
  const [selectedValues, setSelectedValues] = useState(state)
  const [searchValue, setSearchValue] = useState("")
  const [tagsList, setTagsList] = useState([])

  const fetchTagsList = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/tags/get")
    setTagsList(response.data)
  }

  useEffect(() => {
    fetchTagsList()
  }, [])

  useEffect(() => {
    setSelectedValues(state)
  }, [state])

  useEffect(() => {
    const filteredtagsList = tagsList.filter(item => !selectedValues.some(selected => selected.id === item.id))
    setTagsList(filteredtagsList)
  }, [selectedValues])

  const ref = useRef(null)

  const onClickOutside = () => {
    const dropdownList = document.querySelectorAll(`#${idList}`)

    Array.from(dropdownList).forEach(dropdown => {
      dropdown.classList.remove("active")
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClickOutside])


  useEffect(() => {
    if (!tagsList || tagsList.length === 0) {
      return
    }
    if (typeof tagsList[0] === 'string' || typeof tagsList[0] === 'number') {
      setState(selectedValues)
    }
  }, [selectedValues])

  const handleClickDropdown = (event) => {
    const dropdownList = event.currentTarget.nextSibling
    dropdownList.classList.toggle("active")

    const searchBar = document.querySelector(`#search-bar`)
    searchBar.focus()
  }

  const onclickMenuItem = (event) => {

    if (typeof tagsList[0] === 'object') {
      const itemID = event.currentTarget.getAttribute("data-index")
      const itemValue = event.currentTarget.textContent

      const alreadySelected = selectedValues.some(item => item.name == itemValue)

      if (alreadySelected == true) {
        return
      }

      setSelectedValues(prevArray => {
        return (
          [...prevArray, {
            "id": parseInt(itemID),
            "tag": itemValue
          }]
        )
      })
      setState(itemID, "add")
      setSearchValue("")
      const searchBar = document.querySelector(`#search-bar`)
      searchBar.focus()
    }

  }

  const deleteMenuItem = (event) => {
    event.stopPropagation()

    const itemID = event.currentTarget.getAttribute("data-item-id")

    const newState = selectedValues.filter(value => value.id != itemID)
    setSelectedValues(newState)
    setState(itemID, "remove")
    setSearchValue("")
  }

  if (!tagsList || tagsList.length === 0) {
    return <p></p>
  }

  const handleCreateTag = async (event) => {
    const searchBarInput = document.querySelector("#search-bar")
    const tagValue = searchBarInput.value

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/tags/create?tagValue=${tagValue}`)

    const newTag = response.data

    setSelectedValues(prevArray => {
      return (
        [...prevArray, {
          "id": parseInt(newTag.id),
          "tag": newTag.tag
        }]
      )
    })
    setState(newTag.id, "add")
    setSearchValue("")
  }

  const handleArchiveTag = async (e, tagId) => {
    e.stopPropagation()

    const response = await httpClient.delete(process.env.REACT_APP_API_URL + `/api/tags/archive/${tagId}`)
    console.log(response.data)

    fetchTagsList && fetchTagsList()
  }

  return (
    <div className="dropdown__container-dropdown--multiselect tags flex--vert" id={`dropdown__${index}`} style={{ width, marginTop }} ref={ref} key={index}>
      <span className="input_field__input_box--text dropdown classic" style={{ marginTop }} onClick={handleClickDropdown}>
        {
          state != undefined ?
            selectedValues.map((value, index) => {
              return (
                <p className={`dropdown-input--text_field`} onClick={deleteMenuItem} style={{ fontSize, fontStyle }} key={index} data-item-id={value.id}>
                  {value.tag}
                  <CloseIcon />
                </p>
              )
            }) : <p></p>
        }
      </span>
      <ul className="input_field__dropdown classic" id={idList} key={index}>
        <div className='search-bar_dropdown_container'>
          <input className="search-bar dropdown" id={`search-bar`} placeholder="Search..." value={searchValue} onChange={event => setSearchValue(event.currentTarget.value)} autoComplete="off" />
          <button onClick={handleCreateTag}>create</button>
        </div>
        {
          typeof tagsList[0] === 'object' && tagsList[0] !== null && (
            tagsList
              .filter(item => Object.values(item).some(value => String(value).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())))
              .map((item, index) => {
                return (
                  <li className='dropdown__item' key={index} data-index={item.id} style={{ fontSize }} onClick={onclickMenuItem}>
                    <div key={index}>
                      {item.tag}
                    </div>
                    <div className='dropdown_item_option' onClick={(e) => handleArchiveTag(e, item.id)}><MdOutlineDeleteSweep /></div>
                  </li>
                )
              })
          )
        }

      </ul>
    </div>
  )
}

DropdownMultiselectTags.defaultProps = {
  marginTop: "5px",
  fontStyle: "italic",
  height: "40px",
}

export default DropdownMultiselectTags
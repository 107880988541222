import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux"
import './ContactsPage.css'
import httpClient from '../components/helpers/httpClient'
import { useNavigate, Link, Navigate } from "react-router-dom"
import MenuBar from "../components/MenuBar"
import Header from "../components/Header"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import { updateFilters, updateContactsList, resetStateContactsPage } from "../store/contactsPage"
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import DropDownFilter from '../components/DropDownFilter'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Dropdown from '../components/Dropdown'
import CheckBox from '../components/CheckBox'
import { DateField } from '@mui/x-date-pickers'
import { toast } from "react-toastify"
import Pagination from '../components/Pagination'

function ContactsPage() {
  const [loadingContacts, setLoadingContacts] = useState(false)
  const [limit, setLimit] = useState(20)
  const [totalPages, setTotalPages] = useState(1)
  const [tableBig, setTableBig] = useState(true)
  const [contactTitle, setContactTitle] = useState("")
  const [isMarried, setIsMarried] = useState(false)
  const [partner, setPartner] = useState({ "id": null, "first_name": null, "last_name": null })
  const [contactsList, setContactsList] = useState([{ "id": null, "first_name": null, "last_name": null }])
  const [birthday, setBirthday] = useState("")
  const [language, setLanguage] = useState("Dutch")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const filters = useSelector(state => state.contactsPage.filters)
  const contacts = useSelector(state => state.contactsPage.contactsList)

  const formSectionAddressInfo = useRef(null)
  const formSectionOtherInfo = useRef(null)

  const arrayOfCities = [...new Set(contacts.map(contact => {
    if (contact.city !== null && contact.city !== undefined) {
      return contact.city
    }
  }).filter(place => place !== undefined))]

  const fetchContacts = async () => {
    setLoadingContacts(true)

    const requestData = {
      filters: {
        city: filters.cityFilter,
        search_word: filters.searchWord,
      },
      sort: filters.sortFilter,
      sortAscending: filters.sortAscending,
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/contacts/get?page=" + filters.page + "&limit=" + limit, requestData)
    dispatch(updateContactsList(response.data[0]))
    setTotalPages(response.data[1])

    setLoadingContacts(false)
  }

  const fetchFullContacts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/contacts/get")
    setContactsList(response.data)
  }

  useEffect(() => {
    fetchContacts()
    fetchFullContacts()

    const inputFields = document.querySelectorAll(".input_field")
  }, [])

  useEffect(() => {

    fetchContacts()
      .catch(err => {
        console.log(err)
      })

  }, [filters])

  const handleOnClickRow = (event) => {
    const contactID = event.currentTarget.getAttribute("data-contact-id")

    navigate(`/contacts/${contactID}`)
  }

  const handleSetSortFilter = (event) => {

    const innerHTML = event.currentTarget.innerHTML
    const updatedFilters = { ...filters }

    switch (innerHTML) {
      case "id":
        if (filters.sortFilter == "id") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "id"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Last Name":
        if (filters.sortFilter == "last_name") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "last_name"
          updatedFilters["sortAscending"] = true
        }
        break
      case "First Name":
        if (filters.sortFilter == "first_name") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "first_name"
          updatedFilters["sortAscending"] = true
        }
        break
      case "City":
        if (filters.sortFilter == "city") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "city"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Email":
        if (filters.sortFilter == "email") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "email"
          updatedFilters["sortAscending"] = true
        }
        break
    }
    updatedFilters["page"] = 1
    dispatch(updateFilters(updatedFilters))
  }


  const handleOnClickDropdownItem = (event) => {

    const filter = event.currentTarget.textContent
    event.target.parentElement.previousSibling.firstChild.textContent = filter
    event.target.parentElement.classList.toggle("active")

    const updatedFilters = { ...filters }

    switch (event.target.parentElement.id) {
      case "contacts__dropdownList--city":
        updatedFilters["cityFilter"] = filter
        document.getElementById("contacts__dropdownList--city").classList.remove("active")
        break
    }

    updatedFilters["page"] = 1
    dispatch(updateFilters(updatedFilters))
  }

  const handleSearchWord = (event) => {
    const updatedFilters = { ...filters }
    updatedFilters["searchWord"] = event.target.value
    updatedFilters["page"] = 1

    dispatch(updateFilters(updatedFilters))
  }

  const handleClearSearchBar = () => {
    if (filters.searchWord == "") {
      return
    } else {
      const updatedFilters = { ...filters }
      updatedFilters["searchWord"] = ""
      updatedFilters["page"] = 1

      dispatch(updateFilters(updatedFilters))
    }
  }

  const handlePagination = (event) => {
    const element = event.currentTarget

    if (element.classList.contains("grayed-out")) {
      return
    }

    const type = element.dataset.type

    const updatedFilters = { ...filters }

    switch (type) {

      case "previous":
        updatedFilters["page"] = filters.page - 1

        dispatch(updateFilters(updatedFilters))
        break
      case "next":
        updatedFilters["page"] = filters.page + 1

        dispatch(updateFilters(updatedFilters))
        break
    }
  }

  const handleCreateNewContact = () => {
    const newContactForm = document.getElementById("new-contact-form")
    const allContactsTable = document.getElementById("all-contacts-table")
    const saveButton = document.getElementById("contacts__btn-save")
    const filters = document.getElementById("customer__table-btns")

    newContactForm.classList.toggle("hide")
    allContactsTable.classList.toggle("big")
    saveButton.classList.toggle("hide")
    filters.classList.toggle("hide")

    setTableBig(!tableBig)
  }

  const handleSaveNewContact = async () => {
    document.getElementById("contacts__btn-save").disabled = true

    let okToSave = true
    const formData = {}

    const emailErrorElement = document.getElementById("input_error_email")
    const email = document.getElementById("customers__email")

    const emailValidation = /\S+@\S+\.\S+/

    const inputElements = document.querySelectorAll('.input_field__input_box--text')

    inputElements.forEach(element => {
      const fieldName = element.name
      formData[fieldName] = element.value
    })

    formData["language"] = language
    formData["is_married"] = isMarried
    formData["partner_id"] = partner.id
    formData["birthday"] = birthday
    formData["title"] = contactTitle

    // let arrayRequiredTextBoxes = []

    // arrayRequiredTextBoxes.forEach((inputElement) => {
    //   if (!inputElement.value) {
    //     inputElement.classList.add("red-border")
    //   }
    // })

    // if (!arrayRequiredTextBoxes.every(item => item.value)) {
    //   okToSave = true
    // }

    if (email.value) {
      if (emailValidation.test(email.value)) {
        email.classList.remove("red-border")
        emailErrorElement.classList.add("hide")
      } else {
        email.classList.add("red-border")
        emailErrorElement.classList.remove("hide")
        okToSave = false
      }
    }

    if (!okToSave) {
      toast.error("Please complete all fields")
      document.getElementById("contacts__btn-save").disabled = false
      return
    }

    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + "/api/contact/create", formData),
      {
        pending: "Creating a new contact...",
        success: "New contact created 👍",
        error: "Something went wrong 😢"
      }
    )

    const contactID = response.data.contact_id
    
    if (response.status == 200) {
      navigate(`/contacts/${contactID}`)
    }

    // cleaning up form

    inputElements.forEach(element => {
      element.value = ""
    })

    setPartner({ "id": null, "first_name": null, "last_name": null })
    setBirthday("")
    setIsMarried(false)
    setLanguage("Dutch")
    setContactTitle("")

    fetchContacts()

    document.getElementById("contacts__btn-save").disabled = false
  }

  return (
    <div className='template'>

      <Header>
        <div className="header__filters" id="customer__table-btns">
          <div className="search-bar" id="customers__search-bar--table" onClick={handleClearSearchBar}>
            <input placeholder="Search contact..." value={filters.searchWord} onChange={handleSearchWord} />
            {filters.searchWord == "" ? <SearchIcon /> : <ClearIcon />}
          </div>
          <DropDownFilter
            idList="contacts__dropdownList--city"
            placeholderText="Filter on city"
            listArray={arrayOfCities.sort((a, b) => a.localeCompare(b))}
            onClickItem={handleOnClickDropdownItem}
            filters={filters}
            value={filters.cityFilter}
            filter={"cityFilter"}
            setValue={updateFilters}
          />
        </div>

        <button className="primary-button-header--right hide" id="contacts__btn-save" onClick={handleSaveNewContact}>SAVE</button>

      </Header>

      <MenuBar />

      <div className="template__body" id='body-contacts'>
        <button className="floating__button--bottom-right" id="add--btn" onClick={handleCreateNewContact} />

        <div className="body__section hide" id="new-contact-form">
          <div className="form__section">

            <Dropdown
              index={"contact-title"}
              listArray={["Heer", "Mevrouw", "Juffrouw", "Professor", "Dokter", "Meester"]}
              state={contactTitle}
              setState={setContactTitle}
              placeholder={"Title"}
              width={"50%"}
            />

            <div className="input_field--combo-5050">
              <div className="input_field">
                <label className="input_field__label" htmlFor="first_name">First name</label>
                <input className="input_field__input_box--text" id="customers__first_name" type="text" name="first_name" />
              </div>
              <div className="input_field">
                <label className="input_field__label" htmlFor="last_name">Last name</label>
                <input className="input_field__input_box--text" id="customers__last_name" type="text" name="last_name" />
              </div>
            </div>

            <CheckBox
              text="Is married?"
              state={isMarried}
              setState={setIsMarried}
              width={"50%"}
              padding={"0px 2.5px"}
              margin={"7.5px 0"}
            />

            {
              isMarried &&
              <>
                <Dropdown
                  index={"contact-partner"}
                  listArray={contactsList}
                  setState={setPartner}
                  placeholder={"Connect to partner"}
                  width={"100%"}
                  searchBar={true}
                />
              </>
            }

            <div className="input_field">
              <label className="input_field__label" htmlFor="email">Email</label>
              <input className="input_field__input_box--text" id="customers__email" type="text" name="email" />
            </div>
            <div className="input_error hide" id="input_error_email">I suspect this is not a valid email address 🤖</div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Business phone</label>
              <input className="input_field__input_box--text" id="customers__business_phone" type="text" name="business_phone" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Home phone</label>
              <input className="input_field__input_box--text" id="customers__home_phone" type="text" name="home_phone" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Mobile phone</label>
              <input className="input_field__input_box--text" id="customers__mobile_phone" type="text" name="mobile_phone" />
            </div>

          </div>

          <div className="form__section" id="address_info" ref={formSectionAddressInfo}>
            <div className="input_field">
              <label className="input_field__label" htmlFor="address_street">Address</label>
              <input className="input_field__input_box--text" id="customers__address" type="text" name="address" />
            </div>
            <div className="input_field--combo-3070">
              <div className="input_field">
                <label className="input_field__label" htmlFor="address_pc">Postal code</label>
                <input className="input_field__input_box--text" id="customers__address_pc" type="text" name="postal" />
              </div>
              <div className="input_field">
                <label className="input_field__label" htmlFor="address_place">City</label>
                <input className="input_field__input_box--text" id="customers__address_place" type="text" name="city" />
              </div>
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="address_province">Province</label>
              <input className="input_field__input_box--text" id="customers__address_place" type="text" name="province" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="address_nr">Country</label>
              <input className="input_field__input_box--text" id="customers__address_country" type="text" name="country" defaultValue={"Belgium"} />
            </div>
          </div>


          <div className="form__section" id="other_info" ref={formSectionOtherInfo}>
            <div className="input_field">
              <label className="input_field__label" htmlFor="birthday">Birthday</label>
              <DateField className="date-picker" format="DD-MM-YYYY" value={birthday} onChange={(newValue) => setBirthday(newValue)} />
            </div>
            <div className="input_field">
              <label className="input_field__label" htmlFor="language">Language</label>
              <Dropdown
                index={"contact-language"}
                listArray={["Dutch", "English", "French", "Spanish", "Other"]}
                setState={setLanguage}
                state={language}
                width={"100%"}
                margin={"0"}
                parentDivRef={formSectionOtherInfo}
              />
            </div>
          </div>

        </div>

        <div className='body__section big' id='all-contacts-table'>
          <div className='table'>
            <div className='table__header-5'>
              <div className='table__header--field' onClick={handleSetSortFilter}>id</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Last Name</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>First Name</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>City</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Email</div>
            </div>
            <div className='table__rows_container'>

              {
                loadingContacts ?
                  <LoadingSpinner /> :

                  contacts.map((contact, index) => {
                    return (
                      <div className='table__row-5' key={index} onClick={handleOnClickRow} data-contact-id={contact.id}>
                        <div className='table__row--field'>{contact.id}</div>
                        <div className='table__row--field'>{contact.last_name}</div>
                        <div className='table__row--field'>{contact.first_name}</div>
                        <div className='table__row--field'>{contact.city}</div>
                        <div className='table__row--field'>{contact.email}</div>
                      </div>
                    )
                  })
              }
            </div>
            <div className="pagination">
              <ArrowBackIosIcon className={`pagination__btn ${filters.page == 1 ? "grayed-out" : ""}`} onClick={handlePagination} data-type="previous" id="prevPage" />
              <ArrowForwardIosIcon className={`pagination__btn ${filters.page == totalPages ? "grayed-out" : ""}`} onClick={handlePagination} data-type="next" id="nextPage" />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ContactsPage
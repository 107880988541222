import React, { useState, useEffect } from 'react'
import './ProjectDetailPage.css'
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import MenuBar from "../components/MenuBar"
import Header from "../components/Header"
import LoadingSpinner from '../components/placeholders/LoadingSpinner'
import httpClient from '../components/helpers/httpClient'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import Label from '../components/Label'
import { projectStatuses } from '../assets/data/data'
import CircularProgress from '@mui/material/CircularProgress'
import BuildingIcon from '../assets/pictos/svg/BuildingIcon'
import PropertyIcon from '../assets/pictos/svg/PropertyIcon'
import Keys from '../assets/pictos/svg/Keys'
import { PopOutWindow } from '../components/PopOutWindow'
import Slider from '../components/Slider'
import { toast } from "react-toastify"
import { FaBorderNone, FaEdit } from "react-icons/fa"
import DropdownMultiselectTags from '../components/DropdownMultiselectTags'
import Note from '../components/Note'
import { PopupWindow } from '../components/PopupWindow'
import PopupWindowProjectDetail from '../components/PopupWindowProjectDetail'
import PopupWindowBuildingDetail from '../components/PopupWindowBuildingDetail'
import PopupWindowUnitDetail from '../components/popup-window-unit-detail/PopupWindowUnitDetail'
import { formatDate } from '../components/helpers/formatDate'
import { DateField } from '@mui/x-date-pickers'
import Dropdown from '../components/Dropdown'
import { noteTypes } from '../assets/data/data'
import Sold from '../assets/pictos/svg/Sold'
import SellIcon from '../assets/pictos/svg/SellIcon'
import PowerSwitch from '../assets/pictos/svg/PowerSwitch'
import RentedIcon from '../assets/pictos/svg/RentedIcon'

function ProjectDetailPage() {
  const [project, setProject] = useState([])
  const [statuses, setStatuses] = useState([])
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingStatus, setloadingStatus] = useState(false)
  const [popupIsActive, setPopupIsActive] = useState(false)
  const [actionType, setActionType] = useState("")
  const [selectedType, setSelectedType] = useState("")
  const [selectedItemID, setSelectedItemID] = useState(null)
  const [selectedItem, setSelectedItem] = useState({})
  const [tags, setTags] = useState([])
  const [notes, setNotes] = useState([])
  const [selectedNote, setSelectedNote] = useState()
  const [notePopupIsActive, setNotePopupIsActive] = useState(false)
  const [noteDetailPopupIsActive, setNoteDetailPopupIsActive] = useState(false)
  const [newNoteObj, setNewNoteObj] = useState({})
  const [contactsList, setContactsList] = useState([{ "id": null, "first_name": null, "last_name": null }])
  const [projectDetailIsActive, setProjectDetailIsActive] = useState(false)
  const [buildingDetailIsActive, setBuildingDetailIsActive] = useState(false)
  const [unitDetailIsActive, setUnitDetailIsActive] = useState(false)
  const [selectedBuilding, setSelectedBuilding] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { projectID } = useParams()

  const fetchProjectStatuses = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/projects/statuses`)
    setStatuses(response.data)
  }

  const fetchProject = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/projects/get/${projectID}`)
    const data = response.data
    setProject(data)

    setLoading(false)
  }

  const fetchTags = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/tags/get/${projectID}?type=project`)
    setTags(response.data)
  }

  const fetchNotes = async () => {
    let id = null
    if (selectedType == 'project') {
      id = projectID
    } else {
      id = selectedItem.id
    }

    if (!id) {
      return
    }

    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/notes/get/${id}?type=${selectedType}`)
    if (response.status == 200) {
      setNotes(response.data)
    }

  }

  const fetchFullContacts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/contacts/get")
    setContactsList(response.data)
  }

  useEffect(() => {
    fetchProject()
    fetchProjectStatuses()
    fetchTags()
    fetchNotes()
    fetchFullContacts()
  }, [])

  useEffect(() => {
    fetchNotes()
  }, [selectedItem, selectedType])

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleEditButton = () => {
    if (edit) {
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  const handleOnClickStatus = async (e) => {
    if (!edit) {
      return
    }

    setloadingStatus(true)

    const statusId = e.currentTarget.getAttribute('data-status-id')
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/project/change-status?projectID=${projectID}&statusID=${statusId}`)
    console.log(response.data)

    fetchProject()
      .then(() => {
        setloadingStatus(false)
      })
  }

  const handleAdd = (e, type, data = null) => {
    e.stopPropagation()

    setSelectedItemID(null)
    setSelectedType(type)
    setActionType("add")

    if (type == "building") {
      setBuildingDetailIsActive(true)
    } else if (type == "unit") {
      setUnitDetailIsActive(true)
    }

    if (data) {
      setSelectedItem(data)
    }
  }

  const handleClickItem = (e, type, itemId, buildingId = null) => {
    e.stopPropagation()

    switch (type) {
      case 'building':
        const building = project.buildings.find(building => building.id == itemId)
        setBuildingDetailIsActive(true)
        setSelectedItem(building)
        setSelectedItemID(itemId)
        break
      case 'unit':
        const thisBuilding = project.buildings.find(building => building.id == buildingId)
        const unit = thisBuilding.units.find(unit => unit.id == itemId)
        setSelectedBuilding(thisBuilding)
        setUnitDetailIsActive(true)
        setSelectedItem(unit)
        setSelectedItemID(itemId)
        setSelectedItem(prevState => ({ ...prevState, building_id: thisBuilding.id }))
        break
    }

    setSelectedType(type)
    setActionType("edit")
  }

  return (
    <div className='template'>

      <Header>
        {
          !popupIsActive &&
          <>
            <div className="back_button" onClick={handleGoBack}><KeyboardBackspaceIcon /> Go Back</div>

            {
              edit ?
                <button className="primary-button-header--right red" id="project__btn-cancel" onClick={handleEditButton}>CLOSE EDIT</button> :
                <button className="primary-button-header--right" id="project__btn-edit" onClick={handleEditButton}>EDIT</button>
            }
          </>
        }
      </Header>

      <MenuBar />

      <PopupWindowProjectDetail
        isActive={projectDetailIsActive}
        setIsActive={setProjectDetailIsActive}
        projectID={projectID}
        project={project}
        setProject={setProject}
        fetchProject={fetchProject}
        contactsList={contactsList}
      />

      <PopupWindowBuildingDetail
        isActive={buildingDetailIsActive}
        setIsActive={setBuildingDetailIsActive}
        actionType={actionType}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        projectID={projectID}
        selectedItemID={selectedItemID}
        fetchProject={fetchProject}
        contactsList={contactsList}
      />

      <PopupWindowUnitDetail
        isActive={unitDetailIsActive}
        setIsActive={setUnitDetailIsActive}
        actionType={actionType}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        project={project}
        projectID={projectID}
        selectedItemID={selectedItemID}
        fetchProject={fetchProject}
        contactsList={contactsList}
        selectedBuilding={selectedBuilding}
      />

      <div className="template__body" id='body-project'>

        <div className='status_bar' id='status-bar'>
          {
            statuses &&
            statuses.map((status, index) => (
              <div className={`status ${status.id == project.status_id?.id ? `active` : ""} ${status.label_color} ${edit ? "editable" : ""}`} key={index} data-index={index} data-status-id={status.id} onClick={handleOnClickStatus}>
                {
                  loadingStatus ?
                    <div className='loading_spinner--circle'><CircularProgress color="inherit" /></div> :
                    <>{status.status_name}</>
                }
              </div>
            ))
          }
        </div>

        <div className='project_detail_wrapper'>
          {
            loading ?
              <LoadingSpinner /> :
              <>
                <div className='body__section project' id='project-detail-project' onClick={(e) => setProjectDetailIsActive(true)}>
                  {
                    project.project_commercial_name ?
                      <div>
                        <p>{project.project_commercial_name}</p>
                        <p>{project.project_placeholder_name}</p>
                      </div>
                      :
                      <div>
                        <p>{project.project_placeholder_name}</p>
                      </div>
                  }
                </div>

                <section className='section__buildings'>
                  {
                    project.buildings &&
                    project.buildings.map((building, index) => (
                      <div className="building" id={building.id} key={index} onClick={(e) => handleClickItem(e, "building", building.id)} >
                        <div className={`body__section project`}>
                          <div className='icon'><BuildingIcon /></div>
                          <p>{building.building_name}</p>
                        </div>
                        <section className='section__units' onClick={(e) => e.stopPropagation()}>
                          {
                            building.units
                              .sort((a, b) => {
                                // Extract numbers from the end of unit names
                                const aMatch = a.unit_name.match(/(\d+)$/);
                                const bMatch = b.unit_name.match(/(\d+)$/);

                                if (aMatch && bMatch) {
                                  // If both unit names end with numbers, sort numerically
                                  return parseInt(aMatch[1]) - parseInt(bMatch[1]);
                                } else {
                                  // Otherwise, sort alphabetically
                                  return a.unit_name.localeCompare(b.unit_name);
                                }
                              })
                              .map((unit, index) => {
                                let icon = null
                                if (unit.status == 'sold') {
                                  icon = <Sold />
                                } else if (unit.status == 'rented') {
                                  icon = <RentedIcon />
                                }

                                return (
                                  <div 
                                    className={`unit ${unit.status}`} 
                                    key={index} 
                                    onClick={(e) => {
                                      handleClickItem(e, "unit", unit.id, building.id)
                                    }} 
                                  >
                                    <div className='unit-name'>
                                      {unit.unit_name}
                                    </div>
                                    <div className='unit-info'>
                                      <div className='unit-info-type'>{unit.type}</div>
                                      <div className='unit-info-icon'>
                                        {icon}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                          }

                          {
                            edit ?
                              <div className="unit new-button" onClick={(e) => handleAdd(e, "unit", { "building_id": building.id })}>add unit</div> : null
                          }
                        </section>

                      </div>
                    ))
                  }

                  {
                    edit ?

                      <div className="building">
                        <div className='body__section new-button' onClick={(e) => handleAdd(e, "building")}>
                          <p>add building</p>
                        </div>
                      </div> : null
                  }

                </section>
              </>
          }
        </div>

      </div>

    </div >
  )
}

export default ProjectDetailPage
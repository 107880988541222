import { React, useEffect, useState } from 'react'
import './ContentEditor.css'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

function ContentEditor({ placeholder, content, setContent, stateType, stateValueName }) {
  const [localContent, setLocalContent] = useState("")

  useEffect(() => {

    if (stateType == 'object') {
      setLocalContent(content[stateValueName])
    } else {
      setLocalContent(content)
    }
  }, [])

  const handleContentChange = (value) => {

    if (stateType == "object") {
      setContent(prevState => ({
        ...prevState,
        [stateValueName]: value
      }))
    } else {
      setContent(value)
    }

    setLocalContent(value)
  }

  return (
    <div className='content_editor__container'>
      <ReactQuill
        className='custom-quill'
        value={localContent}
        onChange={handleContentChange}
        placeholder={placeholder}
      />
    </div>
  )
}

export default ContentEditor
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { CiSquarePlus } from "react-icons/ci"
import { FaRegEdit } from "react-icons/fa"
import { GoUnlink } from "react-icons/go"
import { HiMiniPower } from "react-icons/hi2"
import { PopupWindow } from '../../PopupWindow'
import { formatDate } from '../../helpers/formatDate'
import PopupDropdown from '../../PopupDropdown'
import { PopupWindowTransaction } from '../../PopupWindowTransaction'
import { toast } from "react-toastify"
import httpClient from '../../helpers/httpClient'

const PropertySection = forwardRef(({ unit, loading, creatingProperty, setCreatingProperty }, ref) => {
  const navigate = useNavigate()
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [selectedPropertySection, setSelectedPropertySection] = useState("details")
  const [editProperty, setEditProperty] = useState(false)
  const [linkPropertyIsActive, setLinkPropertyIsActive] = useState(false)
  const [propertySearchValue, setPropertySearchValue] = useState("")
  const [properties, setProperties] = useState([])
  const [propertiesOfUnit, setPropertiesOfUnit] = useState([])
  const [transactionPopupIsActive, setTransactionPopupIsActive] = useState(false)
  const [selectedTransactionID, setSelectedTransactionID] = useState(null)

  const initialPropertyState = {
    cadastral_nr: '',
    location: '',
    function: '',
    surface: '',
    GFAa_underground: '',
    GFAa: '',
    GFAb: '',
    GFAc: '',
    EPC_label: '',
    description: '',
    co_ownership_share_numerator: '',
    co_ownership_share_denominator: ''
  }
  const [newPropertyObj, setNewPropertyObj] = useState(initialPropertyState)

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    handleCreateProperty
  }))

  // Add useEffect for initial data loading
  useEffect(() => {
    if (unit?.id) {
      fetchPropertyFns()
    }
  }, [unit])

  // Add property update function
  const handleUpdateProperty = async () => {
    if (!selectedProperty) return

    const data = { ...selectedProperty }
    await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + `/api/properties/update`, data),
      {
        success: `Property has been updated!`,
        error: "Something went wrong",
        pending: `Updating property...`
      }
    )

    setEditProperty(false)
    fetchPropertyFns()
  }

  // Add save button to property details when editing
  const renderPropertyDetailsActions = () => {
    if (!editProperty) return null

    return (
      <div className="property-details-actions">
        <button className="action-button-header" onClick={handleUpdateProperty}>
          Save Changes
        </button>
        <button className="action-button-header red" onClick={() => setEditProperty(false)}>
          Cancel
        </button>
      </div>
    )
  }

  const handleLinkProperty = async (propertyID) => {
    const data = {
      propertyID: propertyID,
      unitID: unit.id
    }
    await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/properties/link`, data), {
      success: `Property has been linked!`,
      error: "Something went wrong",
      pending: `Linking property...`
    })

    fetchPropertyFns()
  }

  const handleUnlinkProperty = async () => {
    const data = {
      propertyID: selectedProperty.id,
      unitID: unit.id
    }
    await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/properties/link?type=unlink`, data), {
      success: `Property has been unlinked!`,
      error: "Something went wrong",
      pending: `Unlinking property...`
    })

    setSelectedProperty(null)
    fetchPropertyFns()
  }

  const handleTogglePropertyStatus = async () => {
    const data = {
      propertyID: selectedProperty.id
    }
    await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/properties/toggle-status`, data), {
      success: `Property status has been updated!`,
      error: "Something went wrong",
      pending: `Updating property status...`
    })

    fetchPropertyFns()
  }

  const setStateSelectedProperty = (key, value) => {
    const prevState = { ...selectedProperty }
    prevState[key] = value
    setSelectedProperty(prevState)
  }

  const setStateNewProperty = (key, value) => {
    const prevState = { ...newPropertyObj }
    prevState[key] = value
    setNewPropertyObj(prevState)
  }

  const handleTransactionClick = (transactionID) => {
    setSelectedTransactionID(transactionID)
    setTransactionPopupIsActive(true)
  }

  const fetchProperties = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/properties/get`)
    if (response.status == 200) {
      setProperties(response.data)
    }
  }

  const fetchPropertiesOfUnit = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/properties/properties-of-unit?unitID=${unit.id}`)
    if (response.status == 200) {
      setPropertiesOfUnit(response.data)
    }
  }

  const fetchPropertyFns = () => {
    Promise.all([
      fetchProperties(),
      fetchPropertiesOfUnit()
    ])
  }

  const handleCreateProperty = async () => {
    if (!newPropertyObj.cadastral_nr) {
      toast.error("Cadastral ID is required")
      return false
    }
    
    const data = { ...newPropertyObj, unitID: unit.id }

    try {
      const createResponse = await httpClient.post(
        process.env.REACT_APP_API_URL + `/api/properties/create`,
        data
      )
      // Reset form and fetch updated data
      setNewPropertyObj(initialPropertyState)
      setCreatingProperty(false)
      fetchPropertyFns()

      return true
    } catch (error) {
      console.error("Error creating property:", error)
      toast.error("Failed to create property")
      return false
    }
  }

  return (
  <div className='popout_body__content_container' id="unit-property-section">
    <div className="panel">
      <h2>Properties</h2>
      {loading.properties && (
        <div className='loading__container'>
          <CircularProgress color="inherit" />
        </div>
      )}
      {!loading.properties && (
        <div className='form__section'>
          <div className='form__section--content'>
            <button className='more--btn no-margin' onClick={() => { setLinkPropertyIsActive(!linkPropertyIsActive); setSelectedProperty(null) }}>+</button>
            <div className='properties_list'>
              {propertiesOfUnit
                .sort((a, b) => (b.is_active === true) - (a.is_active === true))
                .map((property, index) => (
                  <div
                    className={`property-item list ${property.is_active ? "" : "inactive"}`}
                    key={index}
                    onClick={() => { setLinkPropertyIsActive(false); setSelectedProperty(property) }}
                  >
                    <div>{property.cadastral_nr}</div>
                    <div>{property.location}</div>
                    <div>{property.function}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>

    <div className='panel'>
      {linkPropertyIsActive && !selectedProperty && (
        <div className='form__section'>
          <PopupWindow
            popupID={"popup-unit-property-create"}
            isActive={creatingProperty}
            setIsActive={setCreatingProperty}
          >
            {/* Property creation form */}
            <div className='date_note--popup'>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="cadastral_nr">Cadastral ID</label>
                <input
                  className="input_field__input_box--text"
                  id="cadastral_nr"
                  type="text"
                  name="cadastral_nr"
                  value={newPropertyObj.cadastral_nr}
                  onChange={(e) => setStateNewProperty("cadastral_nr", e.currentTarget.value)}
                />
              </div>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="location">Location</label>
                <input
                  className="input_field__input_box--text"
                  id="location"
                  type="text"
                  name="location"
                  value={newPropertyObj.location}
                  onChange={(e) => setStateNewProperty("location", e.currentTarget.value)}
                />
              </div>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="function">Type</label>
                <input
                  className="input_field__input_box--text"
                  id="function"
                  type="text"
                  name="function"
                  value={newPropertyObj.function}
                  onChange={(e) => setStateNewProperty("function", e.currentTarget.value)}
                />
              </div>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="surface">Surface (m²)</label>
                <input
                  className="input_field__input_box--text"
                  id="surface"
                  type="number"
                  name="surface"
                  value={newPropertyObj.surface}
                  onChange={(e) => setStateNewProperty("surface", e.currentTarget.value)}
                />
              </div>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="GFAa_underground">GFAa underground (m²)</label>
                <input
                  className="input_field__input_box--text"
                  id="GFAa_underground"
                  type="number"
                  name="GFAa_underground"
                  value={newPropertyObj.GFAa_underground}
                  onChange={(e) => setStateNewProperty("GFAa_underground", e.currentTarget.value)}
                />
              </div>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="GFAa">GFAa (m²)</label>
                <input
                  className="input_field__input_box--text"
                  id="GFAa"
                  type="number"
                  name="GFAa"
                  value={newPropertyObj.GFAa}
                  onChange={(e) => setStateNewProperty("GFAa", e.currentTarget.value)}
                />
              </div>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="GFAb">GFAb (m²)</label>
                <input
                  className="input_field__input_box--text"
                  id="GFAb"
                  type="number"
                  name="GFAb"
                  value={newPropertyObj.GFAb}
                  onChange={(e) => setStateNewProperty("GFAb", e.currentTarget.value)}
                />
              </div>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="GFAc">GFAc (m²)</label>
                <input
                  className="input_field__input_box--text"
                  id="GFAc"
                  type="number"
                  name="GFAc"
                  value={newPropertyObj.GFAc}
                  onChange={(e) => setStateNewProperty("GFAc", e.currentTarget.value)}
                />
              </div>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="EPC_label">EPC label</label>
                <input
                  className="input_field__input_box--text"
                  id="EPC_label"
                  type="text"
                  name="EPC_label"
                  value={newPropertyObj.EPC_label}
                  onChange={(e) => setStateNewProperty("EPC_label", e.currentTarget.value)}
                />
              </div>
              <div className="input_field--combo-grid co-ownership-fraction">
                <label className="input_field__label">Co-ownership shares</label>
                <div className="fraction-inputs">
                  <input
                    className="input_field__input_box--text"
                    type="number"
                    name="co_ownership_share_numerator"
                    value={newPropertyObj.co_ownership_share_numerator}
                    onChange={(e) => setStateNewProperty("co_ownership_share_numerator", e.currentTarget.value)}
                  />
                  <span className="fraction-divider">/</span>
                  <input
                    className="input_field__input_box--text"
                    type="number"
                    name="co_ownership_share_denominator"
                    value={newPropertyObj.co_ownership_share_denominator}
                    onChange={(e) => setStateNewProperty("co_ownership_share_denominator", e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="input_field">
                <label className="input_field__label" htmlFor="description">Description</label>
                <textarea
                  className="input_field__input_box--text-area"
                  id="description"
                  type="text"
                  name="description"
                  value={newPropertyObj.description}
                  onChange={(e) => setStateNewProperty("description", e.currentTarget.value)}
                />
              </div>
            </div>
          </PopupWindow>

          <h2>Connect property</h2>
          <input
            className='search-bar--list'
            type="text"
            name="property_name"
            value={propertySearchValue}
            onChange={(e) => setPropertySearchValue(e.currentTarget.value)}
            placeholder="Search property"
          />
          <div className='properties_list'>
            <button className='list-button' onClick={() => setCreatingProperty(true)}>
              <CiSquarePlus /> Create new property
            </button>
            {properties
              .filter(property => !propertiesOfUnit.some(unitProperty => unitProperty.cadastral_nr === property.cadastral_nr))
              .filter(property => (
                property.cadastral_nr.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                property.location.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                property.function.toLowerCase().includes(propertySearchValue.toLowerCase())
              ))
              .map((property, index) => (
                <div className='property-item' key={index} onClick={() => handleLinkProperty(property.id)}>
                  <div>{property.cadastral_nr}</div>
                  <div>{property.location}</div>
                  <div>{property.function}</div>
                </div>
              ))}
          </div>
        </div>
      )}

      {selectedProperty && (
        <div className='form__section'>
          <div className='title_container'>
            <h2
              className={`click-title ${selectedPropertySection === "details" ? "selected" : ""}`}
              onClick={() => setSelectedPropertySection("details")}
            >
              Property details
            </h2>
            <h2
              className={`click-title ${selectedPropertySection === "transactions" ? "selected" : ""}`}
              onClick={() => setSelectedPropertySection("transactions")}
            >
              Transactions
            </h2>
          </div>

          {selectedPropertySection === "details" && (
            <>
              <div className='action-buttons'>
                <button className='icon-button' title="Edit property details" onClick={() => setEditProperty(!editProperty)}>
                  <FaRegEdit />
                </button>
                <button className='icon-button' title="Toggle active state" onClick={handleTogglePropertyStatus}>
                  <HiMiniPower />
                </button>
                <button className='icon-button red' title="Unlink property" onClick={handleUnlinkProperty}>
                  <GoUnlink />
                </button>
              </div>
              <div className='property_details--container'>
                {renderPropertyDetailsActions()}
                <div className='property_details--content'>
                  {/* Property details form fields */}
                  <div className='current-owners-section'>
                    <h3>Current Owners</h3>
                    <div className='owners-list'>
                      {selectedProperty.current_owners?.map((owner, index) => (
                        <div
                          key={index}
                          className='owner-item'
                          onClick={() => navigate(`/${owner.type}s/${owner.id}`)}
                          style={{ cursor: 'pointer' }}
                        >
                          <div className='owner-info'>
                            <span className='owner-name'>{owner.name}</span>
                            <span className='owner-type'>{owner.ownership_type}</span>
                          </div>
                          <div className='owner-percentage'>
                            {owner.ownership_percentage}%
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                    <label className="input_field__label" htmlFor="cadastral_nr">Cadastral ID</label>
                    <input
                      className="input_field__input_box--text"
                      id="cadastral_nr"
                      type="text"
                      name="cadastral_nr"
                      value={selectedProperty.cadastral_nr}
                      onChange={(e) => setStateSelectedProperty("cadastral_nr", e.currentTarget.value)}
                      disabled={!editProperty}
                    />
                  </div>
                  <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                    <label className="input_field__label" htmlFor="location">Location</label>
                    <input
                      className="input_field__input_box--text"
                      id="location"
                      type="text"
                      name="location"
                      value={selectedProperty.location}
                      onChange={(e) => setStateSelectedProperty("location", e.currentTarget.value)}
                      disabled={!editProperty}
                    />
                  </div>
                  <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                    <label className="input_field__label" htmlFor="function">Type</label>
                    <input
                      className="input_field__input_box--text"
                      id="function"
                      type="text"
                      name="function"
                      value={selectedProperty.function}
                      onChange={(e) => setStateSelectedProperty("function", e.currentTarget.value)}
                      disabled={!editProperty}
                    />
                  </div>
                  <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                    <label className="input_field__label" htmlFor="surface">Surface (m²)</label>
                    <input
                      className="input_field__input_box--text"
                      id="surface"
                      type="number"
                      name="surface"
                      value={selectedProperty.surface}
                      onChange={(e) => setStateSelectedProperty("surface", e.currentTarget.value)}
                      disabled={!editProperty}
                    />
                  </div>
                  <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                    <label className="input_field__label" htmlFor="GFAa_underground">GFAa underground (m²)</label>
                    <input
                      className="input_field__input_box--text"
                      id="GFAa_underground"
                      type="number"
                      name="GFAa_underground"
                      value={selectedProperty.GFAa_underground}
                      onChange={(e) => setStateSelectedProperty("GFAa_underground", e.currentTarget.value)}
                      disabled={!editProperty}
                    />
                  </div>
                  <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                    <label className="input_field__label" htmlFor="GFAa">GFAa (m²)</label>
                    <input
                      className="input_field__input_box--text"
                      id="GFAa"
                      type="number"
                      name="GFAa"
                      value={selectedProperty.GFAa}
                      onChange={(e) => setStateSelectedProperty("GFAa", e.currentTarget.value)}
                      disabled={!editProperty}
                    />
                  </div>
                  <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                    <label className="input_field__label" htmlFor="GFAb">GFAb (m²)</label>
                    <input
                      className="input_field__input_box--text"
                      id="GFAb"
                      type="number"
                      name="GFAb"
                      value={selectedProperty.GFAb}
                      onChange={(e) => setStateSelectedProperty("GFAb", e.currentTarget.value)}
                      disabled={!editProperty}
                    />
                  </div>
                  <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                    <label className="input_field__label" htmlFor="GFAc">GFAc (m²)</label>
                    <input
                      className="input_field__input_box--text"
                      id="GFAc"
                      type="number"
                      name="GFAc"
                      value={selectedProperty.GFAc}
                      onChange={(e) => setStateSelectedProperty("GFAc", e.currentTarget.value)}
                      disabled={!editProperty}
                    />
                  </div>
                  <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                    <label className="input_field__label" htmlFor="EPC_label">EPC label</label>
                    <input
                      className="input_field__input_box--text"
                      id="EPC_label"
                      type="text"
                      name="EPC_label"
                      value={selectedProperty.EPC_label}
                      onChange={(e) => setStateSelectedProperty("EPC_label", e.currentTarget.value)}
                      disabled={!editProperty}
                    />
                  </div>
                  <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                    <label className="input_field__label">Co-ownership shares</label>
                    <div className="fraction-inputs">
                      <input
                        className="input_field__input_box--text"
                        type="number"
                        name="co_ownership_share_numerator"
                        value={selectedProperty.co_ownership_share_numerator}
                        onChange={(e) => setStateSelectedProperty("co_ownership_share_numerator", e.currentTarget.value)}
                        disabled={!editProperty}
                      />
                      <span className="fraction-divider">/</span>
                      <input
                        className="input_field__input_box--text"
                        type="number"
                        name="co_ownership_share_denominator"
                        value={selectedProperty.co_ownership_share_denominator}
                        onChange={(e) => setStateSelectedProperty("co_ownership_share_denominator", e.currentTarget.value)}
                        disabled={!editProperty}
                      />
                    </div>
                  </div>
                  <div className={`input_field--simple grid-2 ${editProperty ? "edit" : ""}`}>
                    <label className="input_field__label" htmlFor="description">Description</label>
                    <textarea
                      className="input_field__input_box--text-area"
                      id="description"
                      type="text"
                      name="description"
                      value={selectedProperty.description}
                      onChange={(e) => setStateSelectedProperty("description", e.currentTarget.value)}
                      disabled={!editProperty}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {selectedPropertySection === "transactions" && (
            <div className='transactions_list'>
              {selectedProperty.transactions
                .sort((a, b) => new Date(b.transaction_id.deed_date) - new Date(a.transaction_id.deed_date))
                .map((transaction, index) => (
                  <div
                    className='transaction-item'
                    key={index}
                    onClick={() => handleTransactionClick(transaction.transaction_id.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div>id: {transaction.transaction_id.id}</div>
                    <div>{transaction.transaction_id.type}</div>
                    <div>{formatDate(transaction.transaction_id.deed_date)}</div>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>

    {selectedTransactionID && (
      <PopupWindowTransaction
        isActive={transactionPopupIsActive}
        setIsActive={setTransactionPopupIsActive}
        transactionActionType="edit"
        selectedTransactionID={selectedTransactionID}
        fetchTransactions={() => { }}
      />
    )}
  </div>
)
})

export default PropertySection 
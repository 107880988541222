import axios from "axios"

const httpClient = axios.create({
    withCredentials: true,
})

httpClient.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 400) {
            return Promise.resolve(error.response)
        }
        return Promise.reject(error)
    }
)

export default httpClient
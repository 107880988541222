import React, { useState, useEffect } from 'react'
import './PopupWindow.css'
import CloseIcon from '@mui/icons-material/Close';

export const closePopupWindow = (popupID, onClose = null) => {
  const popup = document.getElementById(popupID)
  popup.classList.remove("active")
  onClose != null && onClose()
}

export function PopupWindow({ popupID, overflow, onClose, children, isActive, setIsActive }) {

  PopupWindow.defaultProps = {
    onClose: () => console.log(""),
    overflow: "scroll",
  }

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  const closePopupWindow = () => {
    setIsActive(false)
    onClose()
  }

  return (
    <div className={`popup__container overlay no-scrollbar ${isActive ? "active" : ""}`} id={popupID}>
      <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />
      <div className='popup__box no-scrollbar' style={{ overflow: overflow }} id={popupID}>
        {children}
      </div>
    </div>
  )
}
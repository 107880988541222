import React, { useState, useEffect, useRef } from 'react'
import './ProductDetailPage.css'
import httpClient from '../components/helpers/httpClient'
import { useNavigate, useParams } from "react-router-dom"
import MenuBar from "../components/MenuBar"
import Header from "../components/Header"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import Dropdown from '../components/Dropdown'
import { toast } from "react-toastify"
import FileDropper from '../components/FileDropper'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import PopupDropdown from '../components/PopupDropdown'
import DropdownMultiselect from '../components/DropdownMultiselect'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CheckBox from '../components/CheckBox'
import { theme } from 'antd'

const ImageViewer = ({ imageUrl, onClose }) => {
  return (
    <div className="image-viewer-overlay" onClick={onClose}>
      <div className="image-viewer-content">
        <img src={imageUrl} alt="Full size" />
        <button className="close-button" onClick={onClose}>×</button>
      </div>
    </div>
  )
}

function ProductDetailPage() {
  const [product, setProduct] = useState({})
  const [edit, setEdit] = useState(false)
  const [fileURL, setFileURL] = useState("")
  const [images, setImages] = useState([])
  const [files, setFiles] = useState([])
  const [prices, setPrices] = useState([])
  const [companies, setCompanies] = useState([])
  const [selectedImage, setSelectedImage] = useState(null)
  const [newPrice, setNewPrice] = useState({
    supplier_id: null,
    installer_id: null,
    product_id_supplier: '',
    unit: 'Stuk',
    price_net_excl_installation: '',
    price_brut_excl_installation: '',
    installation_percentage_on_net: '',
    installation_percentage_on_brut: '',
    installation_fixed_price: '',
    price_net_incl_installation: '',
    price_brut_incl_installation: '',
    margin_excl_installation: '',
    margin_incl_installation: '',
    installation_type: 'percentage_on_net',
    manually_edited_brut_incl: false
  })
  const [groups, setGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [expandedSection, setExpandedSection] = useState(null)
  const unitOptions = ['Stuk', 'm²', 'm³', 'lm', 'kg', 'l'] // Available unit options

  const navigate = useNavigate()
  const { productID } = useParams()

  const fetchProduct = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/products/get?productID=${productID}`)

    const data = response.data

    setImages(data.product_images || [])
    setFiles(data.product_files || [])
    setFileURL(data.technical_fiche_link)
    setPrices(data.product_prices || [])
    setSelectedGroups(data.product_groups || [])
    setProduct(response.data)
  }

  const fetchCompanies = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/companies/get`)
    // Map companies to only include id and name
    const mappedCompanies = response.data.map(company => ({
      id: company.id,
      name: company.company_name
    }))
    setCompanies(mappedCompanies)
  }

  const fetchGroups = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + '/api/product/groups/get')
    setGroups(response.data)
  }

  useEffect(() => {
    fetchProduct()
    fetchCompanies()
    fetchGroups()
  }, [])

  // Keep fileURL synced with product.technical_fiche_link
  useEffect(() => {
    if (product.technical_fiche_link !== undefined) {
      setFileURL(product.technical_fiche_link)
    }
  }, [product.technical_fiche_link])

  // Update product.technical_fiche_link when fileURL changes
  useEffect(() => {
    if (fileURL !== undefined && product.technical_fiche_link !== fileURL) {
      setProduct(prev => ({
        ...prev,
        technical_fiche_link: fileURL
      }))
    }
  }, [fileURL])

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleEditProduct = async () => {
    if (edit) {
      const editButton = document.querySelector("#product__btn-edit")
      editButton.disabled = true

      // Add price
      await AddPrice()

      // Update product
      try {
        const response = await toast.promise(
          httpClient.put(process.env.REACT_APP_API_URL + `/api/product/update?productID=${productID}`, product),
          {
            pending: "Update product...",
            success: "Product has been updated 👍",
            error: "Something went wrong 😢"
          }
        )
        console.log(response.data)

        fetchProduct()

        editButton.disabled = false
        setEdit(!edit)
      } catch (error) {
        console.error("Error updating product:", error)
        editButton.disabled = false
      }
    } else {
      setEdit(true)
    }
  }

  const handleChangeInput = (e, type) => {
    // Skip any attempts to change product_internal_sku
    if (type === "product_internal_sku") {
      return;
    }
    
    const newProductObj = { ...product }
    newProductObj[type] = e.currentTarget.value

    setProduct(newProductObj)
  }

  const handleUploadFiles = async (e, type) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData()

      // Append all files
      Array.from(e.target.files).forEach(file => {
        formData.append('files[]', file)
      })
      formData.append('product_id', productID)

      try {
        const response = await toast.promise(
          httpClient.post(
            process.env.REACT_APP_API_URL + '/api/products/upload-files?type=' + type,
            formData
          ),
          {
            pending: 'Uploading images...',
            success: 'Images uploaded successfully',
            error: 'Failed to upload images'
          }
        )

        // Add all new image URLs to the existing images
        const newUrls = response.data.urls.map(item => item.url)
        if (type == "image") {
          setImages([...images, ...newUrls])
        } else {
          setFiles([...files, ...newUrls])
        }
      } catch (error) {
        console.error('Upload error:', error)
      }
    }

    fetchProduct()
  }

  const handleDeleteFile = async (fileId) => {
    try {
      await toast.promise(
        httpClient.delete(
          `${process.env.REACT_APP_API_URL}/api/products/delete-file?fileID=${fileId}`
        ),
        {
          pending: 'Deleting file...',
          success: 'File deleted successfully',
          error: 'Failed to delete file'
        }
      )

      // Refresh product data after deletion
      fetchProduct()
    } catch (error) {
      console.error('Delete error:', error)
    }
  }

  const handlePriceChange = (field, value) => {
    const updates = { ...newPrice, [field]: value }
    
    // Track if brut price including installation is manually edited
    if (field === 'price_brut_incl_installation') {
      updates.manually_edited_brut_incl = true;
      
      // If net price incl installation exists, update the margin incl installation
      if (updates.price_net_incl_installation) {
        updates.margin_incl_installation = (((parseFloat(value) / parseFloat(updates.price_net_incl_installation)) - 1) * 100).toFixed(2);
      }
    } else if (field === 'margin_incl_installation' && updates.price_net_incl_installation) {
      // When margin is changed, allow overriding the manually edited flag
      updates.manually_edited_brut_incl = false;
      updates.price_brut_incl_installation = (parseFloat(updates.price_net_incl_installation) * (1 + parseFloat(value) / 100)).toFixed(2);
    }

    // Net/Brut Calculation Logic (excluding installation)
    if (field === 'price_net_excl_installation' && updates.margin_excl_installation) {
      // Calculate gross price based on net price and margin
      updates.price_brut_excl_installation = (parseFloat(updates.price_net_excl_installation) * (1 + parseFloat(updates.margin_excl_installation) / 100)).toFixed(2)
      
      // By default, brut price incl installation is the same as brut price excl installation
      // Only update if not manually edited
      if (!updates.manually_edited_brut_incl) {
        updates.price_brut_incl_installation = updates.price_brut_excl_installation;
      }
    } else if (field === 'price_brut_excl_installation' && updates.price_net_excl_installation) {
      // Calculate margin based on net and gross prices
      updates.margin_excl_installation = (((parseFloat(updates.price_brut_excl_installation) / parseFloat(updates.price_net_excl_installation)) - 1) * 100).toFixed(2)
      
      // By default, brut price incl installation is the same as brut price excl installation
      // Only update if not manually edited
      if (!updates.manually_edited_brut_incl) {
        updates.price_brut_incl_installation = updates.price_brut_excl_installation;
      }
    } else if (field === 'margin_excl_installation' && updates.price_net_excl_installation) {
      // Calculate gross price based on net price and new margin
      updates.price_brut_excl_installation = (parseFloat(updates.price_net_excl_installation) * (1 + parseFloat(updates.margin_excl_installation) / 100)).toFixed(2)
      
      // By default, brut price incl installation is the same as brut price excl installation
      // Only update if not manually edited
      if (!updates.manually_edited_brut_incl) {
        updates.price_brut_incl_installation = updates.price_brut_excl_installation;
      }
    }

    // Handle installation type changes
    if (field === 'installation_type') {
      // Reset all installation price fields
      updates.installation_percentage_on_net = '';
      updates.installation_percentage_on_brut = '';
      updates.installation_fixed_price = '';
      updates.manually_edited_brut_incl = false; // Reset manual edit flag
      
      // Reset prices including installation to match prices excluding installation
      if (updates.price_net_excl_installation) {
        updates.price_net_incl_installation = updates.price_net_excl_installation;
      }
      if (updates.price_brut_excl_installation) {
        updates.price_brut_incl_installation = updates.price_brut_excl_installation;
      }
      if (updates.margin_excl_installation) {
        updates.margin_incl_installation = updates.margin_excl_installation;
      }
    }

    // Calculate prices including installation based on the selected installation type
    if (!updates.manually_edited_brut_incl && (
        field === 'installation_percentage_on_net' || 
        field === 'installation_percentage_on_brut' || 
        field === 'installation_fixed_price' ||
        (field === 'installation_type' && (
          updates.installation_percentage_on_net || 
          updates.installation_percentage_on_brut || 
          updates.installation_fixed_price
        ))
    )) {
      updateInstallationPrices(updates);
    } else if (!updates.manually_edited_brut_incl && updates.price_net_excl_installation) {
      if (updates.installation_type === 'percentage_on_net' && updates.installation_percentage_on_net) {
        const installationAmount = parseFloat(updates.price_net_excl_installation) * parseFloat(updates.installation_percentage_on_net) / 100;
        updates.price_net_incl_installation = (parseFloat(updates.price_net_excl_installation) + installationAmount).toFixed(2);
        
        // If we have brut price excl installation, calculate brut price incl installation
        // Only if not manually edited
        if (updates.price_brut_excl_installation && updates.margin_incl_installation) {
          updates.price_brut_incl_installation = (parseFloat(updates.price_net_incl_installation) * (1 + parseFloat(updates.margin_incl_installation) / 100)).toFixed(2);
        } else if (updates.price_brut_excl_installation) {
          // Keep the same margin for incl as excl
          const installationAmount = parseFloat(updates.price_net_excl_installation) * parseFloat(updates.installation_percentage_on_net) / 100;
          updates.price_brut_incl_installation = (parseFloat(updates.price_brut_excl_installation) + installationAmount).toFixed(2);
          updates.margin_incl_installation = (((parseFloat(updates.price_brut_incl_installation) / parseFloat(updates.price_net_incl_installation)) - 1) * 100).toFixed(2);
        }
      } else if (updates.installation_type === 'percentage_on_brut' && updates.installation_percentage_on_brut && updates.price_brut_excl_installation) {
        const installationAmount = parseFloat(updates.price_brut_excl_installation) * parseFloat(updates.installation_percentage_on_brut) / 100;
        updates.price_brut_incl_installation = (parseFloat(updates.price_brut_excl_installation) + installationAmount).toFixed(2);
        
        // If we have net price excl installation, calculate net price incl installation
        if (updates.price_net_excl_installation) {
          // Keep the same ratio between net and brut
          const ratio = parseFloat(updates.price_brut_excl_installation) / parseFloat(updates.price_net_excl_installation);
          updates.price_net_incl_installation = (parseFloat(updates.price_brut_incl_installation) / ratio).toFixed(2);
          updates.margin_incl_installation = (((parseFloat(updates.price_brut_incl_installation) / parseFloat(updates.price_net_incl_installation)) - 1) * 100).toFixed(2);
        }
      } else if (updates.installation_type === 'fixed_price' && updates.installation_fixed_price) {
        const fixedPrice = parseFloat(updates.installation_fixed_price);
        updates.price_net_incl_installation = (parseFloat(updates.price_net_excl_installation) + fixedPrice).toFixed(2);
        
        if (updates.price_brut_excl_installation) {
          updates.price_brut_incl_installation = (parseFloat(updates.price_brut_excl_installation) + fixedPrice).toFixed(2);
          updates.margin_incl_installation = (((parseFloat(updates.price_brut_incl_installation) / parseFloat(updates.price_net_incl_installation)) - 1) * 100).toFixed(2);
        } else if (updates.margin_incl_installation) {
          updates.price_brut_incl_installation = (parseFloat(updates.price_net_incl_installation) * (1 + parseFloat(updates.margin_incl_installation) / 100)).toFixed(2);
        }
      }
    }

    // Calculate margin for including installation
    // Only update price_brut_incl_installation based on margin when it wasn't manually edited
    if (field === 'price_net_incl_installation') {
      if (updates.price_brut_incl_installation && !updates.manually_edited_brut_incl) {
        // If we have a margin, update brut price accordingly
        if (updates.margin_incl_installation) {
          updates.price_brut_incl_installation = (parseFloat(value) * (1 + parseFloat(updates.margin_incl_installation) / 100)).toFixed(2);
        }
      }
      
      // Always update margin based on current values if both prices exist
      if (updates.price_brut_incl_installation) {
        updates.margin_incl_installation = (((parseFloat(updates.price_brut_incl_installation) / parseFloat(value)) - 1) * 100).toFixed(2);
      }
    }

    setNewPrice(updates)
  }

  // Helper function to update installation prices based on type
  const updateInstallationPrices = (updates) => {
    if (!updates.price_net_excl_installation) return updates;
    
    // Skip updating price_brut_incl_installation if it was manually edited
    const skipBrutUpdate = updates.manually_edited_brut_incl;
    
    if (updates.installation_type === 'percentage_on_net' && updates.installation_percentage_on_net) {
      const installationAmount = parseFloat(updates.price_net_excl_installation) * parseFloat(updates.installation_percentage_on_net) / 100;
      updates.price_net_incl_installation = (parseFloat(updates.price_net_excl_installation) + installationAmount).toFixed(2);
      
      if (updates.price_brut_excl_installation && !skipBrutUpdate) {
        // Maintain the same margin ratio or use the margin_incl_installation if available
        if (updates.margin_incl_installation) {
          updates.price_brut_incl_installation = (parseFloat(updates.price_net_incl_installation) * (1 + parseFloat(updates.margin_incl_installation) / 100)).toFixed(2);
        } else {
          const ratio = parseFloat(updates.price_brut_excl_installation) / parseFloat(updates.price_net_excl_installation);
          updates.price_brut_incl_installation = (parseFloat(updates.price_net_incl_installation) * ratio).toFixed(2);
          updates.margin_incl_installation = (((parseFloat(updates.price_brut_incl_installation) / parseFloat(updates.price_net_incl_installation)) - 1) * 100).toFixed(2);
        }
      }
    } else if (updates.installation_type === 'percentage_on_brut' && updates.installation_percentage_on_brut && updates.price_brut_excl_installation) {
      const installationAmount = parseFloat(updates.price_brut_excl_installation) * parseFloat(updates.installation_percentage_on_brut) / 100;
      
      if (!skipBrutUpdate) {
        updates.price_brut_incl_installation = (parseFloat(updates.price_brut_excl_installation) + installationAmount).toFixed(2);
      }
      
      if (updates.price_net_excl_installation) {
        // Calculate net price and margin
        if (skipBrutUpdate && updates.price_brut_incl_installation) {
          // If brut was manually set and we have net excl, calculate net incl based on the same ratio
          const ratio = parseFloat(updates.price_brut_excl_installation) / parseFloat(updates.price_net_excl_installation);
          updates.price_net_incl_installation = (parseFloat(updates.price_brut_incl_installation) / ratio).toFixed(2);
        } else {
          const ratio = parseFloat(updates.price_brut_excl_installation) / parseFloat(updates.price_net_excl_installation);
          updates.price_net_incl_installation = (parseFloat(updates.price_brut_incl_installation) / ratio).toFixed(2);
        }
        updates.margin_incl_installation = (((parseFloat(updates.price_brut_incl_installation) / parseFloat(updates.price_net_incl_installation)) - 1) * 100).toFixed(2);
      }
    } else if (updates.installation_type === 'fixed_price' && updates.installation_fixed_price) {
      const fixedPrice = parseFloat(updates.installation_fixed_price);
      updates.price_net_incl_installation = (parseFloat(updates.price_net_excl_installation) + fixedPrice).toFixed(2);
      
      if (updates.price_brut_excl_installation && !skipBrutUpdate) {
        updates.price_brut_incl_installation = (parseFloat(updates.price_brut_excl_installation) + fixedPrice).toFixed(2);
        updates.margin_incl_installation = (((parseFloat(updates.price_brut_incl_installation) / parseFloat(updates.price_net_incl_installation)) - 1) * 100).toFixed(2);
      } else if (updates.margin_incl_installation && !skipBrutUpdate) {
        updates.price_brut_incl_installation = (parseFloat(updates.price_net_incl_installation) * (1 + parseFloat(updates.margin_incl_installation) / 100)).toFixed(2);
      }
    }
    
    return updates;
  }

  const AddPrice = async () => {
    try {
      if (!newPrice.supplier_id && !newPrice.installer_id) {
        toast.error('Please select a supplier or installer')
        return;
      }

      // Create a clean copy of the newPrice object without the manually_edited_brut_incl flag
      const priceToSend = { ...newPrice };
      delete priceToSend.manually_edited_brut_incl;

      await httpClient.post(process.env.REACT_APP_API_URL + '/api/products/add-price', {
        ...priceToSend,
        product_id: productID,
        supplier_id: newPrice.supplier_id?.id,
        installer_id: newPrice.installer_id?.id,
        SKU_supplier: newPrice.product_id_supplier,
        supplier_name: newPrice.supplier_id?.name,
      })

      // Reset form and refresh prices
      setNewPrice({
        supplier_id: null,
        installer_id: null,
        product_id_supplier: '',
        unit: 'Stuk',
        price_net_excl_installation: '',
        price_brut_excl_installation: '',
        installation_percentage_on_net: '',
        installation_percentage_on_brut: '',
        installation_fixed_price: '',
        price_net_incl_installation: '',
        price_brut_incl_installation: '',
        margin_excl_installation: '',
        margin_incl_installation: '',
        installation_type: 'percentage_on_net',
        manually_edited_brut_incl: false
      })

      // Refresh product data to get updated prices
      fetchProduct()
    } catch (error) {
      console.error('Add price error:', error)
    }
  }

  const handleGroupsChange = async (group, action) => {
    try {
      if (action === "add") {
        await httpClient.post(process.env.REACT_APP_API_URL + '/api/product/groups/add-to-group', {
          product_id: productID,
          group_id: group.id
        })
        setSelectedGroups(prev => [...prev, group])
      } else {
        await httpClient.post(process.env.REACT_APP_API_URL + `/api/product/groups/remove-from-group`, {
          product_id: productID,
          group_id: group.id
        })
        setSelectedGroups(prev => prev.filter(g => g.id !== group.id))
      }
    } catch (error) {
      console.error('Error updating groups:', error)
      // revert the local state change on error
      fetchProduct()
    }
  }

  const toggleSectionExpand = (sectionId) => {
    if (expandedSection === sectionId) {
      setExpandedSection(null) // Collapse if already expanded
    } else {
      setExpandedSection(sectionId) // Expand the clicked section
    }
  }

  const handleDeletePrice = async (priceId) => {
    try {
      const response = await toast.promise(
        httpClient.delete(
          `${process.env.REACT_APP_API_URL}/api/products/delete-price?priceID=${priceId}`
        ),
        {
          pending: 'Deleting price...',
          success: 'Price deleted successfully',
          error: {
            render({data}) {
              // When the promise is rejected, data will contain the error
              if (data && data.response && data.response.data && data.response.data.message) {
                return data.response.data.message;
              }
              return 'Failed to delete price';
            }
          }
        }
      );

      // Refresh prices after deletion
      fetchProduct();
    } catch (error) {
      console.error('Error deleting price:', error);
      // Already handled by toast
    }
  };

  return (
    <div className='template'>

      <Header>
        <div className="back_button" onClick={handleGoBack}><KeyboardBackspaceIcon /> Go Back</div>

        <div className='header__options--right'>
          <button className='primary-button-header' id="product__btn-edit" onClick={handleEditProduct}>{edit ? "SAVE" : "EDIT DETAILS"}</button>
          {edit && <button className='primary-button-header red' id="product__btn-edit" onClick={() => setEdit(false)}>CANCEL</button>}
        </div>
      </Header>

      <MenuBar />

      <div className="template__body" id='body-product-detail'>
        <div 
          className={`body__section ${expandedSection ? 'has-expanded' : ''}`} 
          id="product-detail"
        >
          {/* Top section with 3 columns */}
          <div 
            className={`form__section ${expandedSection === 'product-info-section' ? 'expanded full-width-content' : ''}`} 
            id='product-info-section'
          >
            <h3 onClick={() => toggleSectionExpand('product-info-section')}>Basic Info</h3>
            <div className="input_field">
              <label className="input_field__label" htmlFor="email">Brand</label>
              <input className="input_field__input_box--text" id="products__brand" type="text" name="brand" value={product.brand} onChange={(e) => handleChangeInput(e, "brand")} disabled={!edit} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Product</label>
              <input className="input_field__input_box--text" id="products__product" type="text" name="product" value={product.product} onChange={(e) => handleChangeInput(e, "product")} disabled={!edit} />
            </div>

            <div className="input_field">
              <label className="input_field__label">Groups</label>
              <DropdownMultiselect
                listArray={groups}
                state={selectedGroups}
                setState={handleGroupsChange}
                width="100%"
                idList="groups-dropdown"
                displayValueKey="group_name"
                allowCreate={true}
                createEndpoint="/api/product/groups/create"
                createValueKey="group_name"
                disabled={!edit}
              />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Description</label>
              <textarea className="input_field__input_box--text" id="product__product_description" type="text" name="product_description" value={product.product_description} onChange={(e) => handleChangeInput(e, "product_description")} disabled={!edit} />
            </div>

            <div className="horizontal-fields">
              <div className="input_field">
                <label className="input_field__label" htmlFor="tel">Product Code Producer</label>
                <input className="input_field__input_box--text" id="products__product_producer_id" type="text" name="product_producer_id" value={product.product_producer_id} onChange={(e) => handleChangeInput(e, "product_producer_id")} disabled={!edit} />
              </div>
              
              <div className="input_field">
                <label className="input_field__label" htmlFor="tel">Product Internal SKU</label>
                <input className="input_field__input_box--text" id="products__product_internal_sku" type="text" name="product_internal_sku" value={product.product_internal_sku} onChange={(e) => handleChangeInput(e, "product_internal_sku")} disabled={true} />
              </div>
            </div>

            <div className="input_field is-active-field">
              <label className="input_field__label">Active Status</label>
              <CheckBox
                state={product.is_active || false}
                setState={(newState) => {
                  setProduct({
                    ...product,
                    is_active: newState
                  })
                }}
                textPosition="right"
                disabled={!edit}
                width="50%"
                textSize="0.8rem"
              />
            </div>

          </div>

          {/* Prices section */}
          <div 
            className={`form__section ${expandedSection === 'prices-section' ? 'expanded' : ''}`} 
            id="prices-section"
          >
            <h3 onClick={() => toggleSectionExpand('prices-section')}>Prices</h3>

            {/* Add new price form */}
            {edit && (
              <div className="add-price-form">
                <div className="price-form-grid">
                  {/* Row 1: Installer, Supplier, SKU, Unit */}
                  <div className="grid-item">
                    <PopupDropdown
                      options={companies}
                      value={newPrice.installer_id}
                      onChange={(installer) => handlePriceChange('installer_id', installer)}
                      title="Select Installer"
                      searchBar={true}
                    />
                  </div>
                  <div className="grid-item">
                    <PopupDropdown
                      options={companies}
                      value={newPrice.supplier_id}
                      onChange={(supplier) => handlePriceChange('supplier_id', supplier)}
                      title="Select Supplier"
                      searchBar={true}
                    />
                  </div>
                  <div className="grid-item">
                    <input
                      type="text"
                      placeholder="SKU Supplier"
                      value={newPrice.product_id_supplier}
                      onChange={(e) => handlePriceChange('product_id_supplier', e.target.value)}
                      className="input_field__input_box--text"
                    />
                  </div>
                  
                  {/* Row 2: Net excl, Margin excl, Gross excl */}
                  <div className="grid-item">
                    <input
                      type="number"
                      placeholder="Net Price excl. installation"
                      value={newPrice.price_net_excl_installation}
                      onChange={(e) => handlePriceChange('price_net_excl_installation', e.target.value)}
                      className="input_field__input_box--text"
                    />
                  </div>
                  <div className="grid-item">
                    <input
                      type="number"
                      placeholder="Margin % excl. installation"
                      value={newPrice.margin_excl_installation}
                      onChange={(e) => handlePriceChange('margin_excl_installation', e.target.value)}
                      className="input_field__input_box--text"
                    />
                  </div>
                  <div className="grid-item">
                    <input
                      type="number"
                      placeholder="Gross Price excl. installation"
                      value={newPrice.price_brut_excl_installation}
                      onChange={(e) => handlePriceChange('price_brut_excl_installation', e.target.value)}
                      className="input_field__input_box--text"
                    />
                  </div>
                  
                  {/* Row 3: Installation fields and type selector */}
                  <div className="grid-item">
                    {newPrice.installation_type === 'percentage_on_net' && (
                      <input
                        type="number"
                        placeholder="Installation % on Net"
                        value={newPrice.installation_percentage_on_net}
                        onChange={(e) => handlePriceChange('installation_percentage_on_net', e.target.value)}
                        className="input_field__input_box--text"
                      />
                    )}
                    {newPrice.installation_type === 'percentage_on_brut' && (
                      <input
                        type="number"
                        placeholder="Installation % on Brut"
                        value={newPrice.installation_percentage_on_brut}
                        onChange={(e) => handlePriceChange('installation_percentage_on_brut', e.target.value)}
                        className="input_field__input_box--text"
                      />
                    )}
                    {newPrice.installation_type === 'fixed_price' && (
                      <input
                        type="number"
                        placeholder="Installation Fixed Price"
                        value={newPrice.installation_fixed_price}
                        onChange={(e) => handlePriceChange('installation_fixed_price', e.target.value)}
                        className="input_field__input_box--text"
                      />
                    )}
                  </div>
                  <div className="grid-item">
                    <div className="price-type-selector">
                      <label>
                        <input
                          type="radio"
                          name="installationType"
                          value="percentage_on_net"
                          checked={newPrice.installation_type === 'percentage_on_net'}
                          onChange={() => handlePriceChange('installation_type', 'percentage_on_net')}
                        />
                        % on Net
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="installationType"
                          value="percentage_on_brut"
                          checked={newPrice.installation_type === 'percentage_on_brut'}
                          onChange={() => handlePriceChange('installation_type', 'percentage_on_brut')}
                        />
                        % on Brut
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="installationType"
                          value="fixed_price"
                          checked={newPrice.installation_type === 'fixed_price'}
                          onChange={() => handlePriceChange('installation_type', 'fixed_price')}
                        />
                        Fixed Price
                      </label>
                    </div>
                  </div>
                  <div className="grid-item">
                    <select
                      value={newPrice.unit}
                      onChange={(e) => handlePriceChange('unit', e.target.value)}
                      className="input_field__input_box--text"
                    >
                      {unitOptions.map((unit, index) => (
                        <option key={index} value={unit}>{unit}</option>
                      ))}
                    </select>
                  </div>
                  
                  {/* Row 4: Net incl, Margin incl, Gross incl */}
                  <div className="grid-item">
                    <input
                      type="number"
                      placeholder="Net Price incl. installation"
                      value={newPrice.price_net_incl_installation}
                      onChange={(e) => handlePriceChange('price_net_incl_installation', e.target.value)}
                      className="input_field__input_box--text"
                    />
                  </div>
                  <div className="grid-item">
                    <input
                      type="number"
                      placeholder="Margin % incl. installation"
                      value={newPrice.margin_incl_installation}
                      onChange={(e) => handlePriceChange('margin_incl_installation', e.target.value)}
                      className="input_field__input_box--text"
                    />
                  </div>
                  <div className="grid-item">
                    <input
                      type="number"
                      placeholder="Gross Price incl. installation"
                      value={newPrice.price_brut_incl_installation}
                      onChange={(e) => handlePriceChange('price_brut_incl_installation', e.target.value)}
                      className="input_field__input_box--text"
                    />
                  </div>
                  <div className="grid-item">
                    {/* Empty cell to maintain 4-column grid */}
                  </div>
                </div>
                
                {/* Add Price button below the grid */}
                <div className="form-button-container">
                  <button
                    onClick={AddPrice}
                    className="primary-button"
                  >
                    Add Price
                  </button>
                </div>
              </div>
            )}

            {/* Display supplier prices */}
            <div className="prices-list">
              <table className="prices-table">
                <thead>
                  <tr>
                    <th>Installer</th>
                    <th>Supplier</th>
                    <th>SKU</th>
                    <th>Unit</th>
                    <th>Net excl. install</th>
                    <th>Margin excl.</th>
                    <th>Brut excl. install</th>
                    <th>Installation</th>
                    <th>Net incl. install</th>
                    <th>Margin incl.</th>
                    <th>Brut incl. install</th>
                    <th>Date</th>
                    {edit && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {prices.map((price, index) => {
                    // Find installer company name
                    const installer = companies.find(c => c.id === price.installer_id);
                    const installerName = installer ? installer.name : 'Unknown';
                    
                    return (
                      <tr key={index}>
                        <td>{installerName}</td>
                        <td>{price.supplier_name}</td>
                        <td>{price.SKU_supplier || '-'}</td>
                        <td>{price.unit}</td>
                        <td>€{price.price_net_excl_installation}</td>
                        <td>{price.margin_excl_installation}%</td>
                        <td>€{price.price_brut_excl_installation}</td>
                        <td>
                          {price.installation_percentage_on_net ? `${price.installation_percentage_on_net}% on net` : 
                           price.installation_percentage_on_brut ? `${price.installation_percentage_on_brut}% on brut` : 
                           price.installation_fixed_price ? `€${price.installation_fixed_price}` : '-'}
                        </td>
                        <td>€{price.price_net_incl_installation}</td>
                        <td>{price.margin_incl_installation}%</td>
                        <td>€{price.price_brut_incl_installation}</td>
                        <td>{new Date(price.record_date).toLocaleDateString()}</td>
                        {edit && (
                          <td>
                            <button
                              className="delete-price-button"
                              onClick={() => handleDeletePrice(price.id)}
                              title="Delete price"
                            >
                              <DeleteOutlineIcon />
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* Images section */}
          <div 
            className={`form__section ${expandedSection === 'image_upload' ? 'expanded' : ''}`} 
            id="image_upload"
          >
            <h3 onClick={() => toggleSectionExpand('image_upload')}>Images</h3>
            <div className='file__uploader_section'>
              <div className="photo-grid">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className="photo-grid-item"
                    onClick={() => setSelectedImage(image.file_url)}
                  >
                    {edit && (
                      <button
                        className="delete-image-button"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteFile(image.id)
                        }}
                      >
                        <CloseIcon />
                      </button>
                    )}
                    <img src={image.thumb_url} alt={`Product ${index + 1}`} />
                  </div>
                ))}
                {edit && (
                  <label className="photo-grid-item add-photo-button">
                    <input
                      type="file"
                      accept="image/*"
                      multiple={true}
                      onChange={(e) => handleUploadFiles(e, "image")}
                    />
                    +
                  </label>
                )}
              </div>
              {selectedImage && (
                <ImageViewer
                  imageUrl={selectedImage}
                  onClose={() => setSelectedImage(null)}
                />
              )}
            </div>
          </div>

          {/* Technical files section */}
          <div 
            className={`form__section ${expandedSection === 'file_upload' ? 'expanded full-width-content' : ''}`} 
            id="file_upload"
          >
            <h3 onClick={() => toggleSectionExpand('file_upload')}>Technical files</h3>
            <div className='file__uploader_section'>
              <div className="file-list">
                {files.map((file, index) => (
                  <div key={index} className="file-list-item">
                    <a href={file.file_url} download className="file-download-link">
                      <FileDownloadIcon />
                      <span className="file-name">{file.file_name || 'Document'}</span>
                    </a>
                    {edit && (
                      <button
                        className="delete-file-button"
                        onClick={() => handleDeleteFile(file.id)}
                      >
                        <CloseIcon />
                      </button>
                    )}
                  </div>
                ))}
                {edit && (
                  <label className="file-list-item add-file-button">
                    <input
                      type="file"
                      multiple={true}
                      onChange={(e) => handleUploadFiles(e, "file")}
                    />
                    +
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ProductDetailPage
import React, { useEffect, useState } from 'react'
import './ImageDropperCloud.css'
import httpClient from './helpers/httpClient'
import { IoImagesOutline } from "react-icons/io5"
import { Upload, message, theme } from 'antd'
import { toast } from "react-toastify"
import CloseIcon from '@mui/icons-material/Close'
import { FaStar } from "react-icons/fa"

function ImageDropperCloud({ 
  hint, disabled, projectID, selectedImage, setSelectedImage,
  fetchProjectImages, projectImages, setProjectImages
 }) {
  const [loading, setLoading] = useState(false)

  const { Dragger } = Upload

  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png']

  const handleUpload = async (file) => {
    setLoading(true)

    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL + `/api/images/upload?projectID=${projectID}`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      )

      if (response.status === 200) {
        setProjectImages(prevImages => [...prevImages, response.data])
        toast.success("Upload successful")
      } else {
        toast.error("Something went wrong", {theme: "dark"})
      }

      setLoading(false)
      return false
    } catch (error) {
      console.error('Upload error:', error)
      setLoading(false)

      if (error.response && error.response.status === 413) {
        toast.error("File is too large. Maximum size is 20MB.", {theme: "dark"})
      } else {
        toast.error("An error occurred while uploading. Please try again.", {theme: "dark"})
      }
      return false
    }
  }

  const props = {
    name: 'file',
    multiple: true,
    disabled: disabled,
    showUploadList: false,
    beforeUpload: (file) => {

      if (!allowedFileTypes.includes(file.type)) {
        toast.error(`Upload failed: Only ${allowedFileTypes.join(', ')} files are allowed`)
        return Upload.LIST_IGNORE // Rejects the file. No need to return false.
      }

      // Call the function to handle upload
      handleUpload(file)

      // Prevent upload from starting automatically
      return false
    },
    onChange(info) {
      console.log(info.file, info.fileList)
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  const handleClickImage = (imageId) => {
    if (imageId == selectedImage) {
      setSelectedImage(null)
    } else {
      setSelectedImage(imageId)
    }
  }

  return (
    <div className='file_dropper__container image-dropper'>

      <div className={`project_images ${projectImages.length == 0 && !loading ? "hide" : ""}`}>
        {
          projectImages.map((image, index) => {
            return (
              <div className={`project_image ${selectedImage == image.id ? "selected" : image.is_main_image ? "main" : ""}`} data-image-id={image.id} onClick={() => handleClickImage(image.id)}>
                {/* <CloseIcon id='delete-image' /> */}
                {
                  image.is_main_image && selectedImage !== image.id ?
                  <FaStar /> : null
                }
                <img src={image.thumb_url} key={index} />
              </div>
            )
          })
        }
        {
          loading ?
            <div className='loading_image'></div> : null
        }
      </div>

      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <IoImagesOutline />
        </p>
        <p className="ant-upload-text">Drop images</p>
        <p className="ant-upload-hint">
          {hint}
        </p>
      </Dragger>
    </div>
  )
}

ImageDropperCloud.defaultProps = {
  "disabled": false
}

export default ImageDropperCloud

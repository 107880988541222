import * as React from "react";

const SellIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1" 
    width="512"
    height="512"
    viewBox="0 0 64 64"
    style={{ transform: "scaleX(-1)" }} // Horizontal flip
  >
    <g
      fill="none"
      stroke="#010101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.965"
    >
      <path d="M46.9 5v-.4c0-1.1.9-2 2-2h1.4c1.1 0 2 .9 2 2v5.9M20.2 28.5v-7.1l16-16.1c.3-.3.7-.3 1 0l16.1 16.1v12.8"></path>
      <path d="M53.5 21.6 37.2 5.3c-.3-.3-.7-.3-1 0L19.9 21.6c-.8.8-2.1.8-2.9 0s-.8-2.1 0-2.9L33.4 2.4c1.9-1.9 4.9-1.9 6.7 0l16.3 16.3c.8.8.8 2.1 0 2.9s-2.1.8-2.9 0M30.4 29.6v-2.2c0-1.4 1.2-2.6 2.6-2.6h7.3c1.4 0 2.6 1.2 2.6 2.6v5.2M13.4 38.1c1.4-1.8 3.3-3.4 5.6-4.8 3.7-2.4 8.5-1.8 11.6 1.1 1.1 1 2.4 1.6 3.9 1.6h5.8c2 0 3.6 1.6 3.6 3.6v.1c0 .9-.3 1.7-.8 2.3"></path>
      <path d="M24.3 52.5c.3-.1.7-.1 1-.1l14-.4c3.3-.1 6.5-.9 9.5-2.4 4.7-2.4 9.1-5.2 13-8.4.5-.4.8-1 .8-1.6s-.2-1.1-.7-1.5c-1.8-1.6-4.4-1.8-6.5-.6-3.4 2.1-7.6 3.5-12.2 4.6-.7.9-1.7 1.4-2.9 1.4h-7M20.1 59.5 15.4 63 1.5 44.5 6.2 41c1.6-1.2 3.9-.9 5.1.7l9.5 12.7c1.3 1.6.9 3.9-.7 5.1"></path>
    </g>
  </svg>
);

export default SellIcon;

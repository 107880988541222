import React, { useState, useEffect } from 'react'
import './PopOutWindow.css'
import CloseIcon from '@mui/icons-material/Close'

export function PopOutWindow({ popupID, onClose, children, isActive, setIsActive, width }) {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(isActive)
  }, [isActive])

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
          closePopoutWindow()
      }
    })
  }, [])

  const closePopoutWindow = () => {
    setActive(false)
    setIsActive(false)
    onClose()
  }

  return (
    <div className={`popout__container ${active ? "active" : ""}`} id={popupID}>
      <div className='popout__box' id={popupID} style={{width}}>
        <CloseIcon id="popup__close-icon" onClick={closePopoutWindow} />
        {children}
      </div>
    </div>
  )
}

PopOutWindow.defaultProps = {
  onClose: () => console.log(""),
  overflow: "scroll",
  width: "50%"
}
const LoadingHouse = ({ color = 'black', width = '100', height = '100' }) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 100 100"
      width={width}
      height={height}
    >
      {/* House walls */}
      <path
        d="M20 80 L20 40 L80 40 L80 80 L20 80"
        fill="none"
        stroke={color}
        strokeWidth="4"
        strokeDasharray="160"
        strokeDashoffset="160"
      >
        <animate
          attributeName="stroke-dashoffset"
          from="160"
          to="0"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite"
        />
      </path>
      
      {/* Roof */}
      <path
        d="M10 40 L50 10 L90 40"
        fill="none"
        stroke={color}
        strokeWidth="4"
        strokeDasharray="120"
        strokeDashoffset="120"
      >
        <animate
          attributeName="stroke-dashoffset"
          from="120"
          to="0"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export default LoadingHouse;
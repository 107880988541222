import React, { useEffect, useState, useMemo } from 'react'
import './FileDropper.css'
import httpClient from './helpers/httpClient'
import { InboxOutlined } from '@ant-design/icons'
import { Upload, message } from 'antd'
import { toast } from "react-toastify"
import CircularProgress from '@mui/material/CircularProgress'

function FileDropper({ type, hint, URL, setURL, setThumbURL, disabled, fileDirectory }) {
  const [loading, setLoading] = useState(false)
  const [fileURL, setFileURL] = useState(null)
  const [fileName, setFileName] = useState(null)

  const { Dragger } = Upload
  const documentPic = require("../assets/pictos/document.png")



  const allowedFileTypes = useMemo(() => {
    switch (type) {
      case 'images':
        return ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
      case 'documents':
        return ['application/pdf', 'application/msword', 
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      default:
        return ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
    }
  }, [type])

  useEffect(() => {
    if (URL != null) {
      setFileURL(URL)
    }
  }, [URL])

  const handleUpload = async (file) => {
    setLoading(true)

    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await toast.promise(
        httpClient.post(
          `${process.env.REACT_APP_API_URL}/api/files/upload?type=${type}&directory=${fileDirectory}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        ),
        {
          pending: "Uploading file...",
          success: `${file.name} file uploaded successfully`,
          error: `${file.name} file upload failed`
        }
      )

      console.log(setThumbURL, type)

      if (setThumbURL && type === "images") {
        setThumbURL(response.data.thumb_url)
      }

      setFileURL(response.data.secure_url)
      setURL(response.data.secure_url)
      setFileName(response.data.public_id)
      setLoading(false)

      return false
    } catch (error) {
      console.error('Upload error:', error)
      setLoading(false)
      return false
    }
  }

  const props = {
    name: 'file',
    multiple: true,
    disabled: disabled,
    beforeUpload: (file) => {

      if (!allowedFileTypes.includes(file.type)) {
        toast.error(`Upload failed: Only ${allowedFileTypes.join(', ')} files are allowed`)
        return Upload.LIST_IGNORE // Rejects the file. No need to return false.
      }

      // Call the function to handle upload
      handleUpload(file)

      // Prevent upload from starting automatically
      return false
    },
    onChange(info) {
      console.log(info.file, info.fileList)
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  const handleHoverOver = (event) => {
    const deleteButton = event.currentTarget.firstChild
    if (!disabled) {
      deleteButton.classList.add("visible")
    }
  }

  const handleHoverOut = (event) => {
    const deleteButton = event.currentTarget.firstChild
    deleteButton.classList.remove("visible")
  }

  const handleDeleteImage = async (e, type) => {
    try {
      const response = await httpClient.delete(
        `${process.env.REACT_APP_API_URL}/api/files/delete?URL=${fileName}&type=${type}&directory=${fileDirectory}`
      )

      console.log(response.data)
      setFileName('')
      setFileURL(null)
      setURL(null)
    } catch (error) {
      console.error('Delete error:', error)
    }
  }

  return (
    <div className='file_dropper__container'>
      {loading ? (
        <div className='loading_container'>
          <CircularProgress color='inherit' />
        </div>
      ) : (
        fileURL ? (
          type === "images" ? (
            <div className='image_wrapper' onMouseOver={handleHoverOver} onMouseLeave={handleHoverOut}>
              <button className='delete_button' onClick={(e) => handleDeleteImage(e, "image")}>delete</button>
              <img src={fileURL} alt="Uploaded" />
            </div>
          ) : (
            type === "documents" ? (
              <div className='document_wrapper' onMouseOver={handleHoverOver} onMouseLeave={handleHoverOut}>
                  <button className='delete_button' onClick={(e) => handleDeleteImage(e, "file")}>delete</button>
                  <a href={fileURL} download={fileName}>
                    <img src={documentPic} alt="Uploaded" />
                    <p>Download file</p>
                  </a>
              </div>
            ) : null // Add a fallback for types other than 'images' or 'documents'
          )
        ) : (
          <>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                {hint}
              </p>
            </Dragger>
          </>
        )
      )}
    </div>
  )
}

export default FileDropper

FileDropper.defaultProps = {
  "disabled": false,
  "fileDirectory": "Products"
}
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './AddCompanyToContact.css'
import Dropdown from './Dropdown'
import httpClient from './helpers/httpClient'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

function AddCompanyToContact({ companiesList, companyState, companyIDState, companyFunctionState, setLoading, contactID, refreshData, setLinkNewCompany, disabled, maxHeightUL }) {
  const [company, setCompany] = useState(companyState)
  const [companyID, setCompanyID] = useState(companyIDState)
  const [companyFunction, setCompanyFunction] = useState(companyFunctionState)

  const navigate = useNavigate()

  AddCompanyToContact.defaultProps = {
    disabled: false,
    maxHeightUL: "150px"
  }

  const setStateDropdownCompany = async (selectedValue) => {
    setCompany(selectedValue.company_name)
    setCompanyID(selectedValue.id)

    if (companyFunction != "") {
      setLoading(true)

      const data = {
        "company_id": selectedValue.id,
        "function": companyFunction,
        "contact_id": contactID
      }
      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/companies/add-link`, data)
      console.log(response.data)

      refreshData()
      setLinkNewCompany(false)
      setLoading(false)
    }
  }

  const handleSaveCompanyFunction = async () => {

    if (company != "") {
      setLoading(true)

      const data = {
        "company_id": companyID,
        "function": companyFunction,
        "contact_id": contactID
      }
      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/companies/add-link`, data)
      console.log(response.data)

      refreshData()
      setLinkNewCompany(false)
      setLoading(false)
    }
  }

  const handleDeleteCompanyLink = async (e) => {
    e.stopPropagation()

    const data = {
      "company_id": companyID,
      "function": companyFunction,
      "contact_id": contactID
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/companies/delete-link`, data)
    console.log(response.data)

    refreshData()
  }

  const handleClick = () => {
    if (disabled) {
      navigate(`/companies/${companyID}`)
    }
  }

  return (
    <div className={`add_company_to_contact__container`} onClick={handleClick}>
      <Dropdown
        listArray={companiesList}
        searchBar={true}
        state={company}
        setState={setStateDropdownCompany}
        margin={"10px 0 0 0"}
        maxHeightUL={maxHeightUL}
        width={"100%"}
        placeholder={"Select company"}
        disabled={disabled}
      />
      <input
        className={`input_field__input_box--text ${disabled ? "disabled" : ""}`}
        placeholder='Add function'
        value={companyFunction}
        onChange={(event) => setCompanyFunction(event.currentTarget.value)}
        onBlur={handleSaveCompanyFunction}
      />
      {
        disabled ?
          <div className='delete_box' onClick={handleDeleteCompanyLink}>
            <DeleteOutlineIcon />
          </div> :
          null
      }
    </div>
  )
}

export default AddCompanyToContact

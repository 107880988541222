import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import httpClient from '../../helpers/httpClient'
import { NumericFormat } from 'react-number-format'
import { toast } from 'react-toastify'

const PriceSection = forwardRef(({ unit, loading }, ref) => {
  const [commercialPrice, setCommercialPrice] = useState({
    land_price: 0,
    construction_price: 0,
    connection_cost: 0,
    contract_cost: 0,
    vat_percentage: 0.21,
  })
  const [transactionPrice, setTransactionPrice] = useState({
    land_price: 0,
    construction_price: 0,
    connection_cost: 0,
    contract_cost: 0,
    vat_percentage: 0.21,
  })
  const [additionalWorkCosts, setAdditionalWorkCosts] = useState({
    cost: 0,
    discount_value: 0,
    actual: 0
  })
  const [paymentSchedule, setPaymentSchedule] = useState([])

  // Expose methods via ref
  useImperativeHandle(ref, () => ({
    handleSavePriceDetails: async () => {
      // Validate payment schedule
      const stageNumbers = paymentSchedule.map(stage => stage.stage_number);
      
      // Check for empty or undefined stage numbers
      if (stageNumbers.some(num => num === undefined || num === null || num === '')) {
        toast.error('Invalid payment schedule: Empty stage numbers');
        return { success: false, error: 'All stages must have a stage number' };
      }

      // Check for duplicates
      const uniqueNumbers = new Set(stageNumbers);
      if (uniqueNumbers.size !== stageNumbers.length) {
        toast.error('Invalid payment schedule: Duplicate stage numbers');
        return { success: false, error: 'Stage numbers must be unique' };
      }

      // Check if numbers are sequential
      const sortedNumbers = [...stageNumbers].sort((a, b) => a - b);
      for (let i = 0; i < sortedNumbers.length - 1; i++) {
        if (sortedNumbers[i + 1] !== sortedNumbers[i] + 1) {
          toast.error('Invalid payment schedule: Non-sequential stage numbers');
          return { success: false, error: 'Stage numbers must be sequential' };
        }
      }

      try {
        await httpClient.post(
          process.env.REACT_APP_API_URL + `/api/unit-price-details/${unit.id}`,
          {
            commercial_price: commercialPrice,
            transactional_price: transactionPrice,
            additional_work_costs: additionalWorkCosts,
            payment_schedule: paymentSchedule
          }
        )
        return { success: true };
      } catch (error) {
        return { success: false, error: 'Failed to save price details' };
      }
    }
  }))

  const fetchPriceDetails = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/unit-price-details/${unit.id}`)
    if (response.status == 200) {
      setCommercialPrice(prevState => ({ ...prevState, ...response.data.commercial_price }))
      setTransactionPrice(prevState => ({ ...prevState, ...response.data.transactional_price }))
      setAdditionalWorkCosts(prevState => ({
        cost: response.data.additional_work_costs?.cost || 0,
        discount_value: response.data.additional_work_costs?.discount_value || 0,
        actual: (response.data.additional_work_costs?.cost || 0) - (response.data.additional_work_costs?.discount_value || 0)
      }))
      setPaymentSchedule(response.data.payment_schedule || [])
    }
  }

  useEffect(() => {
    fetchPriceDetails()
  }, [])

  const formatNumber = (number) => {
    if (!number && number !== 0) return ''
    // Convert to string, replace dot with comma for display, then add thousand spaces
    return number.toString()
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  const calculateTotal = (prices) => {
    // Convert all values to numbers and default to 0 if invalid
    const landPrice = parseFloat(prices.land_price) || 0
    const constructionPrice = parseFloat(prices.construction_price) || 0
    const connectionCost = parseFloat(prices.connection_cost) || 0
    const contractCost = parseFloat(prices.contract_cost) || 0
    const vatPercentage = parseFloat(prices.vat_percentage) || 0

    const subtotalForVat = constructionPrice + connectionCost + contractCost
    const subtotal = landPrice + subtotalForVat
    const vat = subtotalForVat * vatPercentage
    return { subtotal, total: subtotal + vat }
  }

  const addPaymentStage = () => {
    setPaymentSchedule(prev => {
      // Find the highest stage_number
      const maxStageNumber = prev.reduce((max, stage) => 
        Math.max(max, stage.stage_number || 0), 0);
      
      return [
        ...prev,
        {
          stage_number: maxStageNumber + 1,
          description: '',
          percentage: 0
        }
      ];
    });
  }

  const updatePaymentStage = (index, field, value) => {
    const newStages = [...paymentSchedule]
    newStages[index][field] = value

    if (field === 'order') {
      newStages.sort((a, b) => a.order - b.order)
    }

    setPaymentSchedule(newStages)
  }

  const deletePaymentStage = (index) => {
    setPaymentSchedule(prev => prev.filter((_, i) => i !== index))
  }

  const { subtotal: commercialSubtotal, total: commercialTotal } = calculateTotal(commercialPrice)
  const { subtotal: transactionSubtotal, total: transactionTotal } = calculateTotal(transactionPrice)

  return (
    <div className="section-container" id="unit-price-section">
      <h2>Price Details</h2>

      <div className="price-section">
        <h3>Commercial Price</h3>
        <div className="price-grid horizontal">
          <div className="price-inputs">
            <div className="input_field--combo-grid">
              <label className="input_field__label">Land Price</label>
              <div className="input-with-euro">
                <span className="euro-sign">€</span>
                <NumericFormat
                  className="input_field__input_box--text"
                  value={commercialPrice.land_price}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setCommercialPrice(prev => ({
                      ...prev,
                      land_price: floatValue || 0
                    }));
                  }}
                />
              </div>
            </div>
            <div className="input_field--combo-grid">
              <label className="input_field__label">Construction Price</label>
              <div className="input-with-euro">
                <span className="euro-sign">€</span>
                <NumericFormat
                  className="input_field__input_box--text"
                  value={commercialPrice.construction_price}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setCommercialPrice(prev => ({
                      ...prev,
                      construction_price: floatValue || 0
                    }));
                  }}
                />
              </div>
            </div>
            <div className="input_field--combo-grid">
              <label className="input_field__label">Connection Cost</label>
              <div className="input-with-euro">
                <span className="euro-sign">€</span>
                <NumericFormat
                  className="input_field__input_box--text"
                  value={commercialPrice.connection_cost}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setCommercialPrice(prev => ({
                      ...prev,
                      connection_cost: floatValue || 0
                    }));
                  }}
                />
              </div>
            </div>
            <div className="input_field--combo-grid">
              <label className="input_field__label">Contract Cost</label>
              <div className="input-with-euro">
                <span className="euro-sign">€</span>
                <NumericFormat
                  className="input_field__input_box--text"
                  value={commercialPrice.contract_cost}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setCommercialPrice(prev => ({
                      ...prev,
                      contract_cost: floatValue || 0
                    }));
                  }}
                />
              </div>
            </div>
            <div className="input_field--combo-grid">
              <label className="input_field__label">VAT %</label>
              <div className="input-with-euro">
                <NumericFormat
                  className="input_field__input_box--text"
                  value={commercialPrice.vat_percentage * 100}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setCommercialPrice(prev => ({
                      ...prev,
                      vat_percentage: floatValue / 100
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="total-row">
            <div>Subtotal: €{formatNumber(commercialSubtotal.toFixed(2))}</div>
            <div>Total (incl. VAT): €{formatNumber(commercialTotal.toFixed(2))}</div>
          </div>
        </div>
      </div>

      <div className="price-section">
        <h3>Transaction Price</h3>
        <div className="price-grid horizontal">
          <div className="price-inputs">
            <div className="input_field--combo-grid">
              <label className="input_field__label">Land Price</label>
              <div className="input-with-euro">
                <span className="euro-sign">€</span>
                <NumericFormat
                  className="input_field__input_box--text"
                  value={transactionPrice.land_price}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  disabled={true}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setTransactionPrice(prev => ({
                      ...prev,
                      land_price: floatValue || 0
                    }));
                  }}
                />
              </div>
            </div>
            <div className="input_field--combo-grid">
              <label className="input_field__label">Construction Price</label>
              <div className="input-with-euro">
                <span className="euro-sign">€</span>
                <NumericFormat
                  className="input_field__input_box--text"
                  value={transactionPrice.construction_price}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  disabled={true}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setTransactionPrice(prev => ({
                      ...prev,
                      construction_price: floatValue || 0
                    }));
                  }}
                />
              </div>
            </div>
            <div className="input_field--combo-grid">
              <label className="input_field__label">Connection Cost</label>
              <div className="input-with-euro">
                <span className="euro-sign">€</span>
                <NumericFormat
                  className="input_field__input_box--text"
                  value={transactionPrice.connection_cost}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  disabled={true}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setTransactionPrice(prev => ({
                      ...prev,
                      connection_cost: floatValue || 0
                    }));
                  }}
                />
              </div>
            </div>
            <div className="input_field--combo-grid">
              <label className="input_field__label">Contract Cost</label>
              <div className="input-with-euro">
                <span className="euro-sign">€</span>
                <NumericFormat
                  className="input_field__input_box--text"
                  value={transactionPrice.contract_cost}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  disabled={true}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setTransactionPrice(prev => ({
                      ...prev,
                      contract_cost: floatValue || 0
                    }));
                  }}
                />
              </div>
            </div>
            <div className="input_field--combo-grid">
              <label className="input_field__label">VAT %</label>
              <div className="input-with-euro">
                <NumericFormat
                  className="input_field__input_box--text"
                  value={transactionPrice.vat_percentage * 100}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  disabled={true}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setTransactionPrice(prev => ({
                      ...prev,
                      vat_percentage: floatValue / 100
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="total-row">
            <div>Subtotal: €{formatNumber(transactionSubtotal.toFixed(2))}</div>
            <div>Total (incl. VAT): €{formatNumber(transactionTotal.toFixed(2))}</div>
          </div>
        </div>
      </div>

      <div className="price-section">
        <h3>Additional Work</h3>
        <div className="price-grid horizontal">
          <div className="price-inputs">
            <div className="input_field--combo-grid">
              <label className="input_field__label">Cost</label>
              <div className="input-with-euro">
                <span className="euro-sign">€</span>
                <NumericFormat
                  className="input_field__input_box--text"
                  value={additionalWorkCosts.cost}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  disabled={true}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setAdditionalWorkCosts(prev => ({
                      ...prev,
                      cost: floatValue || 0,
                      actual: (floatValue || 0) - (prev.discount_value || 0)
                    }));
                  }}
                />
              </div>
            </div>
            <div className="input_field--combo-grid">
              <label className="input_field__label">Discount</label>
              <div className="input-with-euro">
                <span className="euro-sign">€</span>
                <NumericFormat
                  className="input_field__input_box--text"
                  value={additionalWorkCosts.discount_value}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  allowNegative={false}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setAdditionalWorkCosts(prev => ({
                      ...prev,
                      discount_value: floatValue || 0
                    }));
                  }}
                />
              </div>
            </div>
            <div className="input_field--combo-grid">
              <label className="input_field__label">Actual</label>
              <div className="input-with-euro">
                <span className="euro-sign">€</span>
                <NumericFormat
                  className="input_field__input_box--text"
                  value={additionalWorkCosts.actual}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  disabled={true}
                  allowNegative={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="price-section">
        <h3>Payment Stages</h3>
        <div className="payment-stages">
          {paymentSchedule.map((stage, index) => (
            <div key={index} className="stage-row">
              <input
                className="input_field__input_box--text"
                type="number"
                value={stage.stage_number}
                onChange={(e) => updatePaymentStage(index, 'stage_number', parseInt(e.target.value))}
              />
              <input
                className="input_field__input_box--text"
                type="text"
                value={stage.stage_name}
                onChange={(e) => updatePaymentStage(index, 'stage_name', e.target.value)}
              />
              <input
                className="input_field__input_box--text"
                type="number"
                value={stage.payment_percentage}
                onChange={(e) => updatePaymentStage(index, 'payment_percentage', parseFloat(e.target.value))}
              />
              <button className="delete-stage-btn" onClick={() => deletePaymentStage(index)}>×</button>
            </div>
          ))}
          <button className="add-stage-btn" onClick={addPaymentStage}>
            Add Stage
          </button>
        </div>
      </div>
    </div>
  )
})

PriceSection.displayName = 'PriceSection'
export default PriceSection 
import React, { useState } from 'react'
import './PopupInput.css'
import { MdClose } from "react-icons/md"
import { PiNoteBlankThin } from "react-icons/pi"


const PopupInput = ({ 
  title, 
  fields, 
  values, 
  onChange, 
  icon: Icon = PiNoteBlankThin,
  width = "400px",
  height = "auto"
}) => {

  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => setIsOpen(!isOpen)

  const handleSave = () => {
    setIsOpen(false)
  }

  const handleChange = (field, value) => {
    onChange({ ...values, [field]: value })
  }

  const hasContent = values && Object.values(values).some(val => val && val.trim() !== '')

  return (
    <div className="popup-input">
      <button 
        className={`popup-input__trigger ${hasContent ? 'has-content' : ''}`} 
        onClick={handleToggle} 
        title={title}
      >
        {Icon ? <Icon /> : null}
      </button>
      
      {isOpen && (
        <div className="popup-input__overlay" onClick={handleToggle}>
          <div 
            className="popup-input__popup" 
            onClick={(e) => e.stopPropagation()}
            style={{ width, height }}
          >
            <div className="popup-input__header">
              <div className="title">{title}</div>
              <button className="popup-input__close-button" onClick={handleToggle}>
                <MdClose />
              </button>
            </div>
            
            <div className="popup-input__fields">
              {fields.map((field) => (
                <div key={field.id} className="popup-input__field">
                  <label htmlFor={field.id}>{field.label}</label>
                  <textarea
                    id={field.id}
                    value={values[field.id] || ''}
                    onChange={(e) => handleChange(field.id, e.target.value)}
                    placeholder={field.placeholder}
                    rows={3}
                  />
                </div>
              ))}
            </div>
            
            <div className="popup-input__actions">
              <button className="popup-input__save-button" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PopupInput 